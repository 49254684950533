import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ICustomAppRepository } from './interfaces/ICustomAppRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ICustomAppCreateRequestServerModel,
  ICustomAppResponseServerModel,
  ICustomAppUpdateRequestServerModel,
} from './serverModels/ICustomAppRepository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const customAppRepository: RepositoryWithContext<ICustomAppRepository> = {
  getAll(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<ICustomAppResponseServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/custom-apps`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  get(
    requestContext: IRequestContext,
    customAppId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ICustomAppResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/custom-apps/${customAppId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  insert(
    requestContext: IRequestContext,
    customApp: ICustomAppCreateRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ICustomAppResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/custom-apps`;
    return restProvider.post(url, customApp, abortSignal, requestContext);
  },

  update(
    requestContext: IRequestContext,
    customApp: ICustomAppUpdateRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ICustomAppResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/custom-apps/${customApp.id}`;
    return restProvider.put(url, customApp, abortSignal, requestContext);
  },

  archive(
    requestContext: IRequestContext,
    customAppId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ICustomAppResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/custom-apps/${customAppId}/archive`;
    return restProvider.put(url, { archived: true }, abortSignal, requestContext);
  },
};
