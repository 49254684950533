import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IUserRepository } from './interfaces/IUserRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IntercomMessageServerModel } from './serverModels/IntercomMessageServerModel.type.ts';
import {
  IUserInfoServerModel,
  IUserProjectsInfoServerModel,
} from './serverModels/UserServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const userRepository: RepositoryWithContext<IUserRepository> = {
  getUserInfo(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IUserInfoServerModel> {
    const url = `${getUrlFactory().getDraftApiUrl()}/user`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getUserProjectsInfo(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IUserProjectsInfoServerModel> {
    const url = `${getUrlFactory().getDraftApiUrl()}/user/projects/`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  sendIntercomMessage(
    requestContext: IRequestContext,
    message: IntercomMessageServerModel,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const url = `${getUrlFactory().getDraftApiUrl()}/user/process-intercom-message/`;
    return restProvider.post(url, message, abortSignal, requestContext);
  },
};
