import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAuditLogRepository } from './interfaces/IAuditLogRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IAuditLogSearchServerRequestModel,
  IAuditLogSearchServerResponseModel,
} from './serverModels/IAuditLogServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const auditLogRepository: RepositoryWithContext<IAuditLogRepository> = {
  search(
    requestContext: IRequestContext,
    searchFilter: IAuditLogSearchServerRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<IAuditLogSearchServerResponseModel> {
    const url = `${getUrlFactory().getDraftApiUrl()}/auditlog/search`;
    return restProvider.post(url, searchFilter, abortSignal, requestContext);
  },
};
