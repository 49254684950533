import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { DummyPeopleCacheKey, touchPeopleDependencies } from './cacheKeys/peopleCacheUtils.ts';
import { getAvailablePlans } from './cacheKeys/planCacheUtils.ts';
import { DummyProjectsKey, touchProjectsDependencies } from './cacheKeys/projectCacheUtils.ts';
import {
  DummySubscriptionsKey,
  getAdministratedSubscriptions,
  getPropertiesKeyBySubscriptionId,
  getSubscriptionBalanceKeyBySubscriptionId,
  getSubscriptionBillingHistoryBySubscriptionId,
  getSubscriptionBillingInformationKeyBySubscriptionId,
  getSubscriptionCurrentSpendingReportBySubscriptionId,
  getSubscriptionTransactionsKeyBySubscriptionId,
  getSubscriptionUsageKeyBySubscriptionId,
  getSubscriptionUsersKeyBySubscriptionId,
  getSubscriptionUsersUsageKeyBySubscriptionId,
  touchGetAdministratedSubscriptions,
  touchSubscriptionAndItsDependencies,
} from './cacheKeys/subscriptionCacheUtils.ts';
import { ISubscriptionRepository } from './interfaces/ISubscriptionRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { anyRoleCacheKey } from './roleRepository.ts';
import { IBillingHistoryItemServerModel } from './serverModels/BillingHistoryItemServerModel.type.ts';
import { IBillingPeriodReportServerModel } from './serverModels/BillingPeriodReportServerModel.type.ts';
import { ISubscriptionTransactionServerModel } from './serverModels/ISubscriptionTransactionServerModel.type.ts';
import { ISubscriptionUserServerModel } from './serverModels/ISubscriptionUserServerModel.type.ts';
import { IPlanServerModel } from './serverModels/PlanServerModel.type.ts';
import {
  IInviteAdminServerModel,
  ISubscriptionAdminServerModel,
} from './serverModels/SubscriptionAdminServerModel.type.ts';
import { SubscriptionPropertyServerModel } from './serverModels/SubscriptionPropertyServerModel.type.ts';
import {
  IAdministratedSubscriptionsServerModel,
  ISubscriptionBillingInformationServerModel,
  ISubscriptionServerModel,
} from './serverModels/SubscriptionServerModel.type.ts';
import { ISubscriptionUsageServerModel } from './serverModels/SubscriptionUsageServerModel.type.ts';
import { ISubscriptionUsersUsageServerModel } from './serverModels/SubscriptionUsersUsageServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

const fiveMinutesCacheExpiration = getMilliseconds({ minutes: 5 });
const tenMinutesCacheExpiration = getMilliseconds({ minutes: 10 });
const oneHourCacheExpiration = getMilliseconds({ hours: 1 });

export const subscriptionRepository: RepositoryWithContext<ISubscriptionRepository> = {
  acceptTermsOfService: async (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/sign-service-agreement`;
    await restProvider.post(url, null, abortSignal, requestContext);

    touchGetAdministratedSubscriptions();
  },

  activateTrial: (
    requestContext: IRequestContext,
    subscriptionName: string,
    acceptTerms: boolean,
    isTrialExtended: boolean = false,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/starttrial`;
    const data = {
      subscriptionName,
      acceptTerms,
      isTrialExtended,
    };
    return restProvider.post(url, data, abortSignal, requestContext).then(
      (result) => {
        touchSubscriptionAndItsDependencies();
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies();
        throw e;
      },
    );
  },

  createNewSubscription: (
    requestContext: IRequestContext,
    subscriptionName: string,
    acceptTerms: boolean,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/startdeveloper`;
    const data = {
      subscriptionName,
      acceptTerms,
    };
    return restProvider.post(url, data, abortSignal, requestContext).then(
      (result) => {
        touchSubscriptionAndItsDependencies();
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies();
        throw e;
      },
    );
  },

  changeSubscriptionPlan: (
    requestContext: IRequestContext,
    planId: Uuid,
    subscriptionId: Uuid,
    skipCreditCardCheck: boolean,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/changeplan/${planId}`;
    return restProvider.post(url, skipCreditCardCheck, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  renameSubscription: (
    requestContext: IRequestContext,
    name: string,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/rename`;
    const subscriptionRenameModel = {
      subscriptionName: name,
    };
    return restProvider.put(url, subscriptionRenameModel, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  promoteUsersToSubscriptionAdmins: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    subscriptionAdmins: UserId[],
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionAdminServerModel[]> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/admins/promote`;
    return restProvider.post(url, subscriptionAdmins, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchPeopleDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  inviteNewSubscriptionAdmin: (
    requestContext: IRequestContext,
    inviteAdmin: IInviteAdminServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionAdminServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${
      inviteAdmin.subscriptionId
    }/admins/invite`;
    return restProvider.post(url, inviteAdmin, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchPeopleDependencies();
        touchSubscriptionAndItsDependencies(inviteAdmin.subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(inviteAdmin.subscriptionId);
        throw e;
      },
    );
  },

  resendInvitationToAdmin: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    userId: UserId,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/admins/${encodeURIComponent(
      userId,
    )}/resend-invitation`;
    return restProvider.put(url, null, abortSignal, requestContext).then(
      () => {
        touchSubscriptionAndItsDependencies(subscriptionId);
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  deleteAdmins: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    adminsToRemove: UserId[],
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionAdminServerModel[]> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/admins/remove`;
    return restProvider.post(url, adminsToRemove, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchPeopleDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  revokeAdmin: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    adminToRevoke: UserId,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionAdminServerModel[]> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/admins/${adminToRevoke}/revoke`;
    return restProvider.delete(url, abortSignal, requestContext).then(
      (result) => {
        touchProjectsDependencies();
        touchPeopleDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        return result;
      },
      (e) => {
        touchSubscriptionAndItsDependencies(subscriptionId);
        throw e;
      },
    );
  },

  exportBillingHistoryItemToPdf: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    billingHistoryItemId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<Blob> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/billing-history/export-to-pdf/${billingHistoryItemId}`;
    return restProvider.getFile(url, null, abortSignal, requestContext);
  },

  getAdministratedSubscriptions: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IAdministratedSubscriptionsServerModel> => {
    return Cache.cache(
      getAdministratedSubscriptions(),
      [DummySubscriptionsKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/user/subscriptions`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      tenMinutesCacheExpiration,
      abortSignal,
    );
  },

  getAvailablePlans: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IPlanServerModel[]> => {
    return Cache.cache(
      getAvailablePlans(),
      null,
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/plan/plans-for-selection`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      oneHourCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionUsers: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionUserServerModel[]> => {
    return Cache.cache(
      getSubscriptionUsersKeyBySubscriptionId(subscriptionId),
      [DummyPeopleCacheKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/users-to-promote`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionCurrentSpendingReport: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IBillingPeriodReportServerModel> => {
    return Cache.cache(
      getSubscriptionCurrentSpendingReportBySubscriptionId(subscriptionId),
      [],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/currentspending`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionUsage: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionUsageServerModel> => {
    return Cache.cache(
      getSubscriptionUsageKeyBySubscriptionId(subscriptionId),
      [DummyPeopleCacheKey, DummyProjectsKey, anyRoleCacheKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/usage`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      tenMinutesCacheExpiration,
      abortSignal,
    );
  },

  getBillingHistoryItems: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IBillingHistoryItemServerModel[]> => {
    return Cache.cache(
      getSubscriptionBillingHistoryBySubscriptionId(subscriptionId),
      [],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/billing-history`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionTransactions: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionTransactionServerModel[]> => {
    return Cache.cache(
      getSubscriptionTransactionsKeyBySubscriptionId(subscriptionId),
      [DummySubscriptionsKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/transactions`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionBalance: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<number> => {
    return Cache.cache(
      getSubscriptionBalanceKeyBySubscriptionId(subscriptionId),
      [DummySubscriptionsKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/balance`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getLatestBillingInformation: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionBillingInformationServerModel> => {
    return Cache.cache(
      getSubscriptionBillingInformationKeyBySubscriptionId(subscriptionId),
      [DummySubscriptionsKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/billingInformation`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      oneHourCacheExpiration,
      abortSignal,
    );
  },

  getSubscriptionUsersUsage: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionUsersUsageServerModel> => {
    return Cache.cache(
      getSubscriptionUsersUsageKeyBySubscriptionId(subscriptionId),
      [DummyPeopleCacheKey, DummyProjectsKey, anyRoleCacheKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/users-usage`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },

  getProperties: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<SubscriptionPropertyServerModel>> => {
    return Cache.cache(
      getPropertiesKeyBySubscriptionId(subscriptionId),
      [DummySubscriptionsKey],
      () => {
        const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/property`;
        return restProvider.get(url, null, abortSignal, requestContext);
      },
      fiveMinutesCacheExpiration,
      abortSignal,
    );
  },
};
