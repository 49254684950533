import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  getUserDummyKeyByUserId,
  getUserPropertiesKeyByUserId,
  touchUserDependencies,
} from './cacheKeys/userCacheUtils.ts';
import { IAccountRepository } from './interfaces/IAccountRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IResendVerificationServerModel,
  IUpdateNameServerModel,
  IUserWelcomeInfoServerModel,
} from './serverModels/AccountServerModels.type.ts';
import { UserPropertyServerModel } from './serverModels/UserPropertyServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 10 });

const getPropertiesValueFn = (
  requestContext: IRequestContext,
  userId: UserId,
  abortSignal?: AbortSignal,
): (() => Promise<UserPropertyServerModel[]>) => {
  const url = `${getUrlFactory().getDraftApiUrl()}/account/${encodeURIComponent(userId)}/property`;
  return () => restProvider.get(url, null, abortSignal, requestContext);
};

export const accountRepository: RepositoryWithContext<IAccountRepository> = {
  completeUserAccount: (
    requestContext: IRequestContext,
    data: IUserWelcomeInfoServerModel,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/account/welcome`;
    return restProvider.post(url, data, abortSignal, requestContext);
  },

  updateName: (
    requestContext: IRequestContext,
    userId: Uuid,
    params: IUpdateNameServerModel,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/account/${encodeURIComponent(
      userId,
    )}/updatename`;
    return restProvider.put(url, params, abortSignal, requestContext);
  },

  /**
   * Obsolete, please use sharedState.userProperties that leverages getProperties
   */
  getProperty: async (
    requestContext: IRequestContext,
    userId: Uuid,
    key: string,
    abortSignal?: AbortSignal,
  ): Promise<UserPropertyServerModel> => {
    if (!key) {
      throw new Error('Provide key of the property to retrieve.');
    }

    const getValueFn = getPropertiesValueFn(requestContext, userId, abortSignal);

    const properties = await Cache.cache(
      getUserPropertiesKeyByUserId(userId),
      [getUserDummyKeyByUserId(userId)],
      getValueFn,
      cacheExpiration,
      abortSignal,
    );
    const property = properties.find((p) => p.key === key);
    return property ? property : Promise.reject({ status: 404 });
  },

  getProperties: (
    requestContext: IRequestContext,
    userId: UserId,
    abortSignal?: AbortSignal,
  ): Promise<UserPropertyServerModel[]> => {
    return Cache.cache(
      getUserPropertiesKeyByUserId(userId),
      [getUserDummyKeyByUserId(userId)],
      getPropertiesValueFn(requestContext, userId, abortSignal),
      cacheExpiration,
      abortSignal,
    );
  },

  upsertProperty: (
    requestContext: IRequestContext,
    userId: Uuid,
    propertyKey: string,
    propertyValue: string,
    abortSignal?: AbortSignal,
  ): Promise<UserPropertyServerModel> => {
    const clearCache = (passOn: UserPropertyServerModel) => {
      touchUserDependencies(userId);
      return passOn;
    };

    const url = `${getUrlFactory().getDraftApiUrl()}/account/${encodeURIComponent(
      userId,
    )}/property/${propertyKey}`;
    return restProvider
      .put(
        url,
        {
          key: propertyKey,
          value: propertyValue,
        },
        abortSignal,
        requestContext,
      )
      .then(clearCache, (fail) => Promise.reject(clearCache(fail)));
  },

  resendVerification: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IResendVerificationServerModel> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/account/resend-verification`;
    return restProvider.post(url, null, abortSignal, requestContext);
  },
};
