import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAiRepository } from './interfaces/IAiRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { CancelOperationParams } from './serverModels/ai/AiServerModels.cancelOperation.ts';
import { AnyAiActionParameters } from './serverModels/ai/AiServerModels.params.ts';
import { CreateAiActionResponse } from './serverModels/ai/AiServerModels.response.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const aiRepository: RepositoryWithContext<IAiRepository> = {
  createAction(
    requestContext: IRequestContext,
    params: AnyAiActionParameters,
    abortSignal?: AbortSignal,
  ): Promise<CreateAiActionResponse> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/ai-operation`;
    const data = prepareRequestData(params);
    return restProvider.post(url, data, abortSignal, requestContext);
  },

  cancelAction(
    requestContext: IRequestContext,
    params: CancelOperationParams,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/ai-operation/cancel`;
    const data = prepareRequestData(params);
    return restProvider.post(url, data, abortSignal, requestContext);
  },
};

const prepareRequestData = (params: AnyAiActionParameters | CancelOperationParams) => {
  const devTopicName = self._envConfig.devAiServiceBusTopicName;
  return devTopicName ? { ...params, devTopicName } : params;
};
