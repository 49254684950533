import { Cache } from '../../_shared/utils/cache.ts';

export function getUserDummyKeyByUserId(userId: string): string {
  return Cache.getKey('user', userId, 'any');
}

export function getUserPropertiesKeyByUserId(userId: string): string {
  return Cache.getKey('user', userId, 'properties');
}

export function getSubscriptionAPIKeyBySubscriptionId(subscriptionId?: Uuid): string {
  return Cache.getKey('subscriptionapikey', subscriptionId);
}

export function touchSubscriptionApiKey(subscriptionId?: Uuid): void {
  Cache.throwAway(getSubscriptionAPIKeyBySubscriptionId(subscriptionId));
}

export function touchUserDependencies(userId: string): void {
  Cache.throwAway(getUserDummyKeyByUserId(userId));
}
