import { isXMLHttpRequest } from '@kontent-ai/errors';
import { assert, Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { DefaultVariantId } from '../_shared/constants/variantIdValues.ts';
import { ContentItemId } from '../_shared/models/ContentItemId.ts';
import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { splitToChunks } from '../_shared/utils/arrayUtils/arrayUtils.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ItemListingBulkAction } from '../applications/contentInventory/content/models/ItemListingBulkActions.ts';
import { ContentItemListingOperationQueryModel } from '../applications/contentInventory/content/models/filter/ContentItemListOperationQueryModel.type.ts';
import {
  IContentItemListScheduledPublishQueryModel,
  IContentItemListScheduledUnpublishQueryModel,
} from '../applications/contentInventory/content/models/filter/ContentItemListScheduleModels.type.ts';
import { IContentItemRestoreQueryModel } from '../applications/contentInventory/content/models/filter/ContentItemRestoreQueryModel.type.ts';
import { createContentItemFilterServerModelFromIds } from '../applications/contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { PublishingState } from '../data/constants/PublishingState.ts';
import {
  getContentItemKeyByProjectId,
  getContentTypeKeyByContentTypeId,
  getContentTypeKeyByProjectId,
  getContentTypeSnippetKeyByProjectId,
  getEntryKeyByProjectId,
  getItemKeyByProjectId,
} from './cacheKeys/itemCacheUtils.ts';
import { invalidateYourTasksCache } from './cacheKeys/tasksUtils.ts';
import { IContentItemRepository } from './interfaces/IContentItemRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ContentItemFilterServerModel,
  ContentItemFilterWithContinuationServerModel,
  GetVariantWithElementsRequestModel,
  IListItemsByIdsInActiveLanguagesQueryServerModel,
  IListItemsWithVariantsByIdsQueryServerModel,
  VariantUsageQueryServerModel,
} from './serverModels/ContentItemFilterWithContinuationServerModel.ts';
import {
  IServerContentItemUsages,
  VariantUsageResponseServerModel,
} from './serverModels/ContentItemUsageModel.type.ts';
import {
  IAssignmentUpdateServerModel,
  IBulkAssignmentUpdateServerModel,
} from './serverModels/IAssignmentServerModel.type.ts';
import {
  IItemListingOperationCheckServerModel,
  IListAllItemsDataByIdsWithVariantsByIdsResponseServerModel,
  IListItemsByComponentsTypeIdResponseServerModel,
  IListItemsByIdsResponseServerModel,
  IListItemsByIdsWithVariantsByIdsResponseServerModel,
  IListItemsCountByComponentsTypeIdResponseServerModel,
  IListItemsResponseServerModel,
  IListingContentItemServerModel,
  IListingContentItemWithMetadataServerModel,
  IPlanningItemsServerModel,
  IPublishWarningsServerModel,
  ItemListOperationResultServerModel,
} from './serverModels/IListingContentItemServerModel.type.ts';
import {
  IContentItemPatchServerModel,
  IContentItemServerModel,
  IContentItemVariantCreationServerModel,
  IContentItemVariantServerModel,
  IContentItemWithVariantServerModel,
  IContentItemWithVariantsServerModel,
  IDuplicateContentItemServerModel,
  INewContentItemServerModel,
} from './serverModels/INewContentItemServerModel.ts';
import { IRevisionServerModel } from './serverModels/IRevisionServerModel.type.ts';
import {
  ITimelineContinuationRequestModel,
  ITimelineServerModel,
} from './serverModels/ITimelineServerModel.type.ts';
import {
  CompiledContentTypeServerModel,
  ICompiledContentTypesByIdsQueryServerModel,
  ICompiledContentTypesServerModel,
} from './serverModels/contentModels/contentTypeServerModels.ts';
import { continueLoadingPredicateToAbortSignal } from './utils/continueLoadingPredicateToAbortSignal.ts';
import { ensureAllRequestedDataFetched } from './utils/ensureAllRequestedDataFetched.ts';
import { JsonPatchOperation } from './utils/jsonPatchConstants.ts';
import { loadContinuousData } from './utils/loadContinuousData.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const ManyIdsCountLimit = 1_000;
const cacheExpiration = getMilliseconds({ seconds: 10 });
const loadAll = () => true;

const fetchContentTypesByIds = (
  requestContext: IRequestContext,
  contentTypeIds: UuidArray,
  abortSignal?: AbortSignal,
): Promise<ICompiledContentTypesServerModel> => {
  const url = `${getUrlFactory().getDraftProjectApiUrl(
    requestContext.projectId,
  )}/item/item-type/get-by-ids`;
  const query: ICompiledContentTypesByIdsQueryServerModel = {
    typeIds: contentTypeIds,
  };
  return restProvider.post(url, query, abortSignal, requestContext);
};

export const contentItemRepository: RepositoryWithContext<IContentItemRepository> = {
  getItemWithVariant(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  async tryGetItemWithVariant(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel | null> {
    try {
      const url = `${getUrlFactory().getDraftProjectApiUrl(
        requestContext.projectId,
      )}/item/${itemId}/variant/${variantId}`;
      return await restProvider.get(url, null, abortSignal, requestContext);
    } catch (e) {
      if (isXMLHttpRequest(e) && e.status === 404) {
        return null;
      }
      throw e;
    }
  },

  getVariantWithElements(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    query: GetVariantWithElementsRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/element`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  getContentTypeForItem(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<CompiledContentTypeServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/type`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  async getContentTypesByIds(
    requestContext: IRequestContext,
    contentTypeIds: ReadonlySet<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<CompiledContentTypeServerModel>> {
    const loadedTypes: CompiledContentTypeServerModel[] = [];
    const missingTypeIds: Uuid[] = [];

    await Promise.all(
      Collection.getValues(contentTypeIds).map(async (typeId) => {
        const cachedType = await Cache.get(getContentTypeKeyByContentTypeId(typeId));
        if (cachedType) {
          loadedTypes.push(cachedType as CompiledContentTypeServerModel);
        } else {
          missingTypeIds.push(typeId);
        }
      }),
    );

    if (missingTypeIds.length) {
      const { data: fetchedContentTypes } = await fetchContentTypesByIds(
        requestContext,
        missingTypeIds,
        abortSignal,
      );

      fetchedContentTypes.forEach((type) =>
        Cache.put(
          getContentTypeKeyByContentTypeId(type._id),
          [
            getContentTypeKeyByProjectId(requestContext.projectId),
            getContentTypeSnippetKeyByProjectId(requestContext.projectId),
          ],
          Promise.resolve(type),
          cacheExpiration,
        ),
      );
      loadedTypes.push(...fetchedContentTypes);
    }

    return loadedTypes;
  },

  getItemsUsage(
    requestContext: IRequestContext,
    filter: VariantUsageQueryServerModel,
    abortSignal?: AbortSignal,
  ): Promise<VariantUsageResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-usage/query`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },

  async projectContainsPublishedItems(
    requestContext: IRequestContext,
    filter: VariantUsageQueryServerModel,
    abortSignal?: AbortSignal,
  ): Promise<boolean> {
    const contentFilter: VariantUsageQueryServerModel = {
      ...filter,
      publishingStates: [PublishingState.Published],
    };

    const items = await this.getItemsUsage(requestContext, contentFilter, abortSignal);
    return items.hasAnyVariant;
  },

  async getItemsAssignedToCurrentUser(
    requestContext,
    orderBy,
    listingRequestOptions,
    abortSignal?,
    continueLoading?: Predicate,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query/assigned-to-me`;

    const filter: ContentItemFilterWithContinuationServerModel = { orderBy };

    const { requestDecorator, decoratedAbortSignal } = continueLoadingPredicateToAbortSignal(
      continueLoading,
      abortSignal,
    );

    return ensureAllRequestedDataFetched(
      (requestOptions) =>
        requestDecorator(() =>
          restProvider.post(
            url,
            {
              ...filter,
              ...requestOptions,
            },
            decoratedAbortSignal,
            requestContext,
          ),
        ),
      listingRequestOptions,
      decoratedAbortSignal,
    );
  },

  getPlanningItems(
    requestContext: IRequestContext,
    variantId: Uuid,
    filter: ContentItemFilterWithContinuationServerModel,
    itemsCountLimit: number,
    continueLoading: Predicate,
    abortSignal?: AbortSignal,
  ): Promise<IPlanningItemsServerModel> {
    return loadContinuousData(
      async (continuationFilter) =>
        await this.getListingItems(
          requestContext,
          variantId,
          {
            // do not set the ordering explicitly if not needed by the app displaying data
            ...filter,
            ...continuationFilter,
            excludeItemsLackingTheVariant: true,
          },
          abortSignal,
        ),
      itemsCountLimit,
      continueLoading,
    );
  },

  getItemsRecentlyEditedByCurrentUser(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IListingContentItemWithMetadataServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query/recently-edited-by-you`;
    return restProvider.post(url, null, abortSignal, requestContext);
  },

  getListingItems(
    requestContext: IRequestContext,
    variantId: Uuid,
    filter: ContentItemFilterWithContinuationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IListItemsResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query/${variantId}`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },

  async getListingItemsByIds(
    requestContext: IRequestContext,
    variantId: Uuid,
    itemIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IListItemsByIdsResponseServerModel> {
    if (itemIds.length === 0) {
      return {
        data: [],
      };
    }

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/query-by-ids`;

    const chunks = splitToChunks(itemIds, ManyIdsCountLimit);
    const promises = chunks.map((chunkIds): Promise<IListItemsByIdsResponseServerModel> => {
      const filterObject = createContentItemFilterServerModelFromIds(Immutable.Set.of(...chunkIds));
      return restProvider.post(url, filterObject, abortSignal, requestContext);
    });

    const results = await Promise.all(promises);
    const items = results.flatMap((result) => result.data);

    return {
      data: items,
    };
  },

  async getListingItemsWithVariantsInActiveLanguagesByIds(
    requestContext: IRequestContext,
    itemIds: ReadonlyArray<Uuid>,
    activeLanguageIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IListItemsByIdsWithVariantsByIdsResponseServerModel> {
    if (itemIds.length === 0 || activeLanguageIds.length === 0) {
      return {
        data: [],
      };
    }

    assert(
      activeLanguageIds.length <= ManyIdsCountLimit,
      () => `${__filename}: too many variantIds requested.`,
    );

    const maxItemsInChunk = Math.floor(ManyIdsCountLimit / activeLanguageIds.length);
    const parallelChunks = splitToChunks(itemIds, maxItemsInChunk);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/active-languages/query-by-ids`;

    const promises = parallelChunks.map((itemIdsInChunk) => {
      const filterObject: IListItemsByIdsInActiveLanguagesQueryServerModel = {
        itemIds: itemIdsInChunk,
        activeLanguageIds,
      };

      return loadContinuousData<IListingContentItemServerModel>(
        async (continuationFilter) =>
          await restProvider.post(
            url,
            {
              ...filterObject,
              ...continuationFilter,
            },
            abortSignal,
            requestContext,
          ),
        ManyIdsCountLimit,
        loadAll,
      );
    });

    const chunkResults = await Promise.all(promises);
    const results = chunkResults.flatMap((result) => result.data);

    return {
      data: results,
    };
  },

  async getAllListingItemsDataWithVariantsByContentItemIds(
    requestContext: IRequestContext,
    contentItemIds: ReadonlyArray<ContentItemId>,
    abortSignal?: AbortSignal,
  ): Promise<IListAllItemsDataByIdsWithVariantsByIdsResponseServerModel> {
    if (contentItemIds.length === 0) {
      return {
        data: [],
        deletedItemIds: [],
        unavailableItemIds: [],
      };
    }

    assert(
      contentItemIds.length <= ManyIdsCountLimit,
      () => `${__filename}: too many variantIds requested.`,
    );

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query-by-ids`;

    const filterObject: IListItemsWithVariantsByIdsQueryServerModel = {
      contentItemIdentifiers: contentItemIds,
    };

    return await restProvider.post(url, filterObject, abortSignal, requestContext);
  },

  async getItemWithAllVariants(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantsServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}`;
    const entry = await restProvider.get(url, null, abortSignal, requestContext);
    return entry.archived ? Promise.reject({ status: 404 }) : entry;
  },

  getContentItemUsage(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IServerContentItemUsages> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/used-in`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getContentItemsByComponentsTypeId(
    requestContext: IRequestContext,
    contentTypeId: Uuid,
    filter: ContentItemFilterWithContinuationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IListItemsByComponentsTypeIdResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query/by-components-type-id/${contentTypeId}`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },

  getContentItemsCountByComponentsTypeId(
    requestContext: IRequestContext,
    contentTypeId: Uuid,
    filter: ContentItemFilterServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IListItemsCountByComponentsTypeIdResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/query/by-components-type-id/count/${contentTypeId}`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },

  createItem(
    requestContext: IRequestContext,
    item: INewContentItemServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantsServerModel> {
    Cache.throwAway(getItemKeyByProjectId(requestContext.projectId));
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/item`;
    return restProvider.post(url, item, abortSignal, requestContext);
  },

  duplicateItem(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    model: IDuplicateContentItemServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantsServerModel> {
    Cache.throwAway(getItemKeyByProjectId(requestContext.projectId));
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/duplicate`;
    return restProvider.post(url, model, abortSignal, requestContext);
  },

  generateUrlSlug(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    elementId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<string> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/element/${elementId}/generate-url-slug`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  patchItemProperty(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    operation: JsonPatchOperation,
    path: string,
    value: any,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemServerModel> {
    Cache.throwAway(getEntryKeyByProjectId(requestContext.projectId));
    const data = [
      {
        op: operation,
        path,
        value,
      },
    ];
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}`;
    return restProvider.patch(url, data, abortSignal, requestContext);
  },

  patchItem(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    patch: IContentItemPatchServerModel[],
    abortSignal?: AbortSignal,
  ): Promise<IContentItemServerModel> {
    Cache.throwAway(getEntryKeyByProjectId(requestContext.projectId));

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}`;
    return restProvider.patch(url, patch, abortSignal, requestContext);
  },

  patchItemVariant(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    contentItemPatches: ReadonlyArray<IContentItemPatchServerModel>,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    Cache.throwAway(getContentItemKeyByProjectId(requestContext.projectId));
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}`;
    return restProvider.patch(url, contentItemPatches, abortSignal, requestContext);
  },

  createVariant(
    requestContext: IRequestContext,
    variant: IContentItemVariantCreationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/item/${
      variant.id.itemId
    }/variant`;
    return restProvider.post(url, { variant }, abortSignal, requestContext);
  },

  createNewVersion(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/new-version`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  publishVariants(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    scheduledToUnpublishAt: DateTimeStamp | null,
    scheduledUnpublishDisplayTimeZone: string | null,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/publish-now`;
    return restProvider.post(
      url,
      {
        listingOperationQuery: query,
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      },
      abortSignal,
      requestContext,
    );
  },

  scheduleVariantsPublish(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: IContentItemListScheduledPublishQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/schedule-publish`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  publishVariant(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    scheduledToUnpublishAt: DateTimeStamp | null,
    scheduledUnpublishDisplayTimeZone: string | null,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/publish-now`;
    return restProvider.put(
      url,
      {
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      },
      abortSignal,
      requestContext,
    );
  },

  discardNewVersion(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/discard-new-version`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  scheduleVariantPublish(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    scheduledToPublishAt: DateTimeStamp,
    scheduledPublishDisplayTimeZone: string | null,
    scheduledToUnpublishAt: DateTimeStamp | null,
    scheduledUnpublishDisplayTimeZone: string | null,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/schedule-publish`;
    return restProvider.put(
      url,
      {
        scheduledToPublishAt,
        scheduledPublishDisplayTimeZone,
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      },
      abortSignal,
      requestContext,
    );
  },

  unpublishVariants(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/unpublish`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  scheduleVariantsUnpublish(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: IContentItemListScheduledUnpublishQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/schedule-unpublish`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  cancelScheduledPublishOfVariants(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/cancel-scheduled-publish`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  unpublishVariant(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/unpublish`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  scheduleVariantUnpublish(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    scheduledToUnpublishAt: DateTimeStamp,
    scheduledUnpublishDisplayTimeZone: string | null,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/schedule-unpublish`;
    return restProvider.put(
      url,
      {
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      },
      abortSignal,
      requestContext,
    );
  },

  cancelVariantScheduledPublish(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/cancel-scheduled-publish`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  cancelVariantScheduledUnpublish(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/cancel-scheduled-unpublish`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  archiveVariants(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/archive`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  archiveVariant(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    Cache.throwAway(getContentItemKeyByProjectId(requestContext.projectId));
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/archive`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  restoreVariants(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: IContentItemRestoreQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/restore`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  updateVariantsAssignments(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    assignment: IBulkAssignmentUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/assign`;
    return restProvider.post(
      url,
      {
        query,
        assignment,
      },
      abortSignal,
      requestContext,
    );
  },

  moveItemsToCollection(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    collectionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/move-to-collection`;
    return restProvider.post(
      url,
      {
        query,
        collectionId,
      },
      abortSignal,
      requestContext,
    );
  },

  restoreVariantsFromArchivedStep(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    assignment: IBulkAssignmentUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ItemListOperationResultServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/restore-from-archived-step`;
    return restProvider.post(
      url,
      {
        query,
        assignment,
      },
      abortSignal,
      requestContext,
    );
  },

  updateVariantAssignment(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    assignment: IAssignmentUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    invalidateYourTasksCache(requestContext.projectId);

    const data: ReadonlyArray<IContentItemPatchServerModel> = [
      {
        op: JsonPatchOperation.Replace,
        path: '/assignment',
        value: assignment,
      },
    ];
    return this.patchItemVariant(requestContext, contentItemId, variantId, data, abortSignal);
  },

  requestTimelineItemsChunk(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    timelineRequestModel: ITimelineContinuationRequestModel,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITimelineServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/timeline`;
    return restProvider.post(url, timelineRequestModel, abortSignal, requestContext);
  },

  getVariantRevision(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    revisionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IRevisionServerModel> {
    const url =
      revisionId === DefaultVariantId
        ? `${getUrlFactory().getDraftProjectApiUrl(
            requestContext.projectId,
          )}/item/${contentItemId}/variant/${variantId}/revision/current`
        : `${getUrlFactory().getDraftProjectApiUrl(
            requestContext.projectId,
          )}/item/${contentItemId}/variant/${variantId}/revision/${revisionId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  restoreVariantRevision(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    revisionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    Cache.throwAway(Cache.getKey('contentItem', requestContext.projectId, 'any'));
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/revision/${revisionId}/restore`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  copyFromVariant(
    requestContext: IRequestContext,
    contentItemId: Uuid,
    variantId: Uuid,
    fromVariantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemWithVariantServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${contentItemId}/variant/${variantId}/copy-from/variant/${fromVariantId}`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  checkListOperations(
    requestContext: IRequestContext,
    variantId: Uuid,
    operations: ItemListingBulkAction[],
    query: ContentItemListingOperationQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<IItemListingOperationCheckServerModel> {
    const itemListOperationCheck = {
      operations,
      query,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/operation-check`;
    return restProvider.post(url, itemListOperationCheck, abortSignal, requestContext);
  },

  checkPublishWarnings(
    requestContext: IRequestContext,
    variantId: Uuid,
    query: ContentItemListingOperationQueryModel,
    abortSignal?: AbortSignal,
  ): Promise<IPublishWarningsServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item-list/${variantId}/check-publish-warnings`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },
};
