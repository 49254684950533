import { ProgressCallback, createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { splitToChunks } from '../_shared/utils/arrayUtils/arrayUtils.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { logWarning } from '../_shared/utils/logWarning.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  getAssetKeyById,
  getAssetLinksKey,
  getAssetUsedInKey,
  touchAssetDependencies,
  touchAssetsDependencies,
} from './cacheKeys/assetCacheUtils.ts';
import { IAssetRepository } from './interfaces/IAssetRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IAssetByIdsQueryServerModel,
  IAssetInsertMetadataServerModel,
  IAssetLinksServerModel,
  IAssetListingServerModel,
  IAssetQueryServerModel,
  IAssetRequestServerModel,
  IAssetResponseServerModel,
  IAssetsArchiveResultServerModel,
  IAssetsByIdsResponseServerModel,
  IAssetsRestoreResponseServerModel,
  IMoveAssetItemToCollectionServerModel,
  IMoveAssetItemToFolderServerModel,
  IMoveAssetsToCollectionRequestServerModel,
  IMoveAssetsToCollectionResultServerModel,
  IMoveAssetsToFolderRequestServerModel,
  IMoveAssetsToFolderResultServerModel,
} from './serverModels/AssetServerModels.type.ts';
import { IContentItemVariantReferenceServerModel } from './serverModels/ContentItemUsageModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const assetCacheMs = 1000;

const minutesPerDay = 24 * 60;
const millisecondsPerDay = minutesPerDay * 60 * 1000;
const millisecondsPerDayMinus2Hours = millisecondsPerDay - 2 * 60 * 60 * 1000;
const assetLinksCacheMs = millisecondsPerDayMinus2Hours;

const assetUsedInCacheMs = 1000;
const assetIdsUpperLimit = 1000;

const setAssetArchived = (
  requestContext: IRequestContext,
  assetId: Uuid,
  archived: boolean,
  abortSignal?: AbortSignal,
): Promise<IAssetResponseServerModel> => {
  touchAssetDependencies(assetId, requestContext.projectId || '');
  const url = `${getUrlFactory().getDraftProjectApiUrl(
    requestContext.projectId,
  )}/asset/${assetId}/archive`;

  return restProvider.put(url, { archived }, abortSignal, requestContext);
};

export const assetRepository: RepositoryWithContext<IAssetRepository> = {
  updateAsset(
    requestContext: IRequestContext,
    asset: IAssetRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    touchAssetDependencies(asset._id, requestContext.projectId || '');
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset/${
      asset._id
    }`;

    return restProvider.put(url, asset, abortSignal, requestContext);
  },

  createAsset(
    requestContext: IRequestContext,
    asset: File,
    metadata: IAssetInsertMetadataServerModel,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset`;

    if (asset.size === 0) {
      logWarning('KCL-11877: File with zero length', {
        FileName: asset.name,
        FileType: asset.type,
        SourceFile: __filename,
      });
    }

    return restProvider.upload(
      url,
      asset,
      metadata,
      uploadProgressCallback,
      abortSignal,
      requestContext,
    );
  },

  replaceAsset(
    requestContext: IRequestContext,
    assetId: Uuid,
    asset: File,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/${assetId}/replace`;
    const result = restProvider.upload(
      url,
      asset,
      null,
      uploadProgressCallback,
      abortSignal,
      requestContext,
    );
    touchAssetDependencies(assetId, requestContext.projectId || '');

    return result;
  },

  getAssetLinks(
    requestContext: IRequestContext,
    assetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IAssetLinksServerModel> {
    return Cache.cache(
      getAssetLinksKey(assetId, requestContext.projectId || ''),
      [getAssetKeyById(assetId, requestContext.projectId || '')],
      () =>
        restProvider.get(
          `${getUrlFactory().getDraftProjectApiUrl(
            requestContext.projectId,
          )}/asset/${assetId}/links`,
          null,
          abortSignal,
          requestContext,
        ),
      assetLinksCacheMs,
      abortSignal,
    );
  },

  getAssets(
    requestContext: IRequestContext,
    assetQueryModel: IAssetQueryServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAssetListingServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset/query`;

    return restProvider.post(url, assetQueryModel, abortSignal, requestContext);
  },

  async getAssetsByIds(
    requestContext: IRequestContext,
    ids: ReadonlySet<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetsByIdsResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/query-by-ids`;
    const distinctAssetIds = Array.from(ids);
    const distinctAssetIdChunks = splitToChunks(distinctAssetIds, assetIdsUpperLimit);
    const promises = distinctAssetIdChunks.map<Promise<IAssetsByIdsResponseServerModel>>(
      (assetIds) => {
        const query: IAssetByIdsQueryServerModel = { assetIds };
        return restProvider.post(url, query, abortSignal, requestContext);
      },
    );
    const results = await Promise.all(promises);
    const data = results.flatMap((value) => value.data);
    return { data };
  },

  getAsset(
    requestContext: IRequestContext,
    assetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    return Cache.cache(
      getAssetKeyById(assetId, requestContext.projectId || ''),
      [],
      () =>
        restProvider.get(
          `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset/${assetId}`,
          null,
          abortSignal,
          requestContext,
        ),
      assetCacheMs,
      abortSignal,
    );
  },

  archiveAsset(
    requestContext: IRequestContext,
    assetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    return setAssetArchived(requestContext, assetId, true, abortSignal);
  },

  archiveAssets(
    requestContext: IRequestContext,
    assetsIds: ReadonlySet<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetsArchiveResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset/archive`;
    const ids = Array.from(assetsIds);

    return restProvider.put(url, { ids }, abortSignal, requestContext);
  },

  moveAssetsToCollection(
    requestContext: IRequestContext,
    items: readonly IMoveAssetItemToCollectionServerModel[],
    abortSignal?: AbortSignal,
  ): Promise<IMoveAssetsToCollectionResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/move-to-collection`;
    const data: IMoveAssetsToCollectionRequestServerModel = {
      items,
    };

    const assetIds = items.map((item) => item.assetId);
    touchAssetsDependencies(assetIds, requestContext.projectId || '');

    return restProvider.put(url, data, abortSignal, requestContext);
  },

  moveAssetsToFolder(
    requestContext: IRequestContext,
    items: readonly IMoveAssetItemToFolderServerModel[],
    abortSignal?: AbortSignal,
  ): Promise<IMoveAssetsToFolderResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/move-to-folder`;
    const data: IMoveAssetsToFolderRequestServerModel = {
      items,
    };

    const assetIds = items.map((item) => item.assetId);
    touchAssetsDependencies(assetIds, requestContext.projectId || '');

    return restProvider.put(url, data, abortSignal, requestContext);
  },

  restoreAsset(
    requestContext: IRequestContext,
    assetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IAssetResponseServerModel> {
    return setAssetArchived(requestContext, assetId, false, abortSignal);
  },

  restoreAssets(
    requestContext: IRequestContext,
    ids: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetsRestoreResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset/restore`;

    return restProvider.put(url, { ids }, abortSignal, requestContext);
  },

  getUsedIn(
    requestContext: IRequestContext,
    assetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IContentItemVariantReferenceServerModel>> {
    return Cache.cache(
      getAssetUsedInKey(assetId, requestContext.projectId || ''),
      [],
      () =>
        restProvider.get(
          `${getUrlFactory().getDraftProjectApiUrl(
            requestContext.projectId,
          )}/asset/${assetId}/used-in`,
          null,
          abortSignal,
          requestContext,
        ),
      assetUsedInCacheMs,
      abortSignal,
    );
  },
};
