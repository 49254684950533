import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { splitToChunks } from '../_shared/utils/arrayUtils/arrayUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAssetRenditionRepository } from './interfaces/IAssetRenditionRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IAssetRenditionRequestServerModel,
  IAssetRenditionResponseServerModel,
  IAssetRenditionsByIdsResponseServerModel,
} from './serverModels/AssetRenditionServerModel.type.ts';
import { IContentItemVariantReferenceServerModel } from './serverModels/ContentItemUsageModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const assetIdsUpperLimit = 1000;

export const assetRenditionRepository: RepositoryWithContext<IAssetRenditionRepository> = {
  createAssetRendition(
    requestContext: IRequestContext,
    assetId: Uuid,
    renditionRequestModel: IAssetRenditionRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAssetRenditionResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/${assetId}/rendition`;

    return restProvider.post(url, renditionRequestModel, abortSignal, requestContext);
  },

  updateAssetRendition(
    requestContext: IRequestContext,
    assetId: Uuid,
    renditionId: Uuid,
    renditionRequestModel: IAssetRenditionRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAssetRenditionResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/${assetId}/rendition/${renditionId}`;

    return restProvider.put(url, renditionRequestModel, abortSignal, requestContext);
  },

  async getRenditionsByIds(
    requestContext: IRequestContext,
    renditionIdsByAssetId: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetRenditionsByIdsResponseServerModel> {
    if (renditionIdsByAssetId.size === 0) {
      return { data: [] };
    }

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset/renditions/get-by-ids`;
    const allRenditions = [...renditionIdsByAssetId].map(([assetId, renditionIds]) => ({
      assetId,
      renditionIds: [...renditionIds],
    }));
    const renditionChunks = splitToChunks(allRenditions, assetIdsUpperLimit);
    const promises = renditionChunks.map((renditions) =>
      restProvider.post(url, { renditions }, abortSignal, requestContext),
    );
    const results = await Promise.all<IAssetRenditionsByIdsResponseServerModel>(promises);
    const data = results.flatMap((value) => value.data);
    return { data };
  },

  async getUsedIn(
    requestContext: IRequestContext,
    assetId: Uuid,
    renditionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IContentItemVariantReferenceServerModel[]> {
    return await restProvider.get(
      `${getUrlFactory().getDraftProjectApiUrl(
        requestContext.projectId,
      )}/asset/${assetId}/rendition/${renditionId}/used-in`,
      null,
      abortSignal,
      requestContext,
    );
  },
};
