import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IEntityWebhookRepository } from './interfaces/IEntityWebhookRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IEntityWebhookServerModel } from './serverModels/IEntityWebhookServerModel.type.ts';
import { IWebhookMessageContentServerModel } from './serverModels/WebhookMessageContentServerModel.type.ts';
import { IWebhookMessageServerModel } from './serverModels/WebhookMessageServerModel.type.ts';
import { IWebhookSecretServerModel } from './serverModels/WebhookSecretServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const entityWebhookRepository: RepositoryWithContext<IEntityWebhookRepository> = {
  getAll(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IEntityWebhookServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  get(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  insert(
    requestContext: IRequestContext,
    webhook: IEntityWebhookServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks`;
    return restProvider.post(url, webhook, abortSignal, requestContext);
  },

  update(
    requestContext: IRequestContext,
    webhook: IEntityWebhookServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}//entity-webhooks/${webhook.id}`;
    return restProvider.put(url, webhook, abortSignal, requestContext);
  },

  archive(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/archive`;
    return restProvider.put(url, { archived: true }, abortSignal, requestContext);
  },

  enable(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/enable`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  disable(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/disable`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  reset(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/reset`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  restore(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IEntityWebhookServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/archive`;
    return restProvider.put(url, { archived: false }, abortSignal, requestContext);
  },

  generateSecret(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IWebhookSecretServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/secret`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getMessages(
    requestContext: IRequestContext,
    webhookId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IWebhookMessageServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/messages`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getMessageDetail(
    requestContext: IRequestContext,
    webhookId: Uuid,
    messageIndex: string,
    abortSignal?: AbortSignal,
  ): Promise<IWebhookMessageContentServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/entity-webhooks/${webhookId}/messages/${messageIndex}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
