import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { getYourTasksCacheKey, invalidateYourTasksCache } from './cacheKeys/tasksUtils.ts';
import { ITaskRepository } from './interfaces/ITaskRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IBaseTaskServerModel,
  ICreateTaskServerModel,
  ITaskServerModel,
  IYourTasksServerModel,
} from './serverModels/ITaskServerModel.type.ts';
import { DataFilter, loadContinuousData } from './utils/loadContinuousData.ts';

const MaxTasksInItem = 1000;
const FiveMinutesInMs = 5 * 60 * 1000;

const restProvider = createRestProvider(createAjaxWithCredentials());

export const taskRepository: RepositoryWithContext<ITaskRepository> = {
  archiveTask(
    requestContext: IRequestContext,
    taskId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaskServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/task/${taskId}/archive`;
    return restProvider.put(url, { archived: true }, abortSignal, requestContext);
  },

  createTask(
    requestContext: IRequestContext,
    task: ICreateTaskServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaskServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/task`;
    return restProvider.post(url, task, abortSignal, requestContext);
  },

  async getTasksForItemVariant(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    includeOnlyOpen?: boolean,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<ITaskServerModel>> {
    const queryParams = new URLSearchParams();

    if (includeOnlyOpen) {
      queryParams.set('includeOnlyOpen', 'true');
    }

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/tasks?${queryParams.toString()}`;

    const response = await loadContinuousData<ITaskServerModel>(
      async (continuationFilter) =>
        await restProvider.post(url, continuationFilter, abortSignal, requestContext),
      MaxTasksInItem,
      () => true,
    );

    return response.data;
  },

  modifyTask(
    requestContext: IRequestContext,
    taskId: Uuid,
    task: IBaseTaskServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaskServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/task/${taskId}`;
    return restProvider.patch(url, task, abortSignal, requestContext);
  },

  completeTask(
    requestContext: IRequestContext,
    taskId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaskServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/task/${taskId}/complete`;
    return restProvider.patch(url, {}, abortSignal, requestContext);
  },

  reopenTask(
    requestContext: IRequestContext,
    taskId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaskServerModel> {
    invalidateYourTasksCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/task/${taskId}/open`;
    return restProvider.patch(url, {}, abortSignal, requestContext);
  },

  async getYourTasks(
    requestContext: IRequestContext,
    limit: number,
    dataFilter: DataFilter<ITaskServerModel>,
    continueLoading: Predicate,
    abortSignal: AbortSignal,
  ): Promise<IYourTasksServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/your-tasks`;

    const getResult = () =>
      loadContinuousData<ITaskServerModel>(
        async (continuationFilter) =>
          await restProvider.post(url, continuationFilter, abortSignal, requestContext),
        limit,
        continueLoading,
        dataFilter,
      );

    const result = await Cache.cache(
      getYourTasksCacheKey(requestContext.projectId),
      null,
      getResult,
      FiveMinutesInMs,
      abortSignal,
    );

    const requestWasCancelled = !continueLoading();
    if (requestWasCancelled) {
      invalidateYourTasksCache(requestContext.projectId);
    }

    return result;
  },
};
