import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IWorkflowRepository } from './interfaces/IWorkflowRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IWorkflowRequestServerModel,
  IWorkflowServerModel,
  IWorkflowValidationRequestServerModel,
  IWorkflowValidationResultServerModel,
  WorkflowStepsUsageServerModel,
  WorkflowsUsageServerModel,
} from './serverModels/WorkflowServerModel.type.ts';

const cacheKeyBase = 'workflows';
const cacheExpiration = getMilliseconds({ minutes: 1 });

const getProjectWorkflowsCacheKey = (projectId: Uuid | undefined) =>
  Cache.getKey(cacheKeyBase, projectId);

const throwAwayWorkflowDependentCache = (projectId: Uuid | undefined) => {
  Cache.throwAway(getProjectWorkflowsCacheKey(projectId));
};

const restProvider = createRestProvider(createAjaxWithCredentials());

export const workflowRepository: RepositoryWithContext<IWorkflowRepository> = {
  getFirstStepRoleCanWorkWith(
    requestContext: IRequestContext,
    roleId: Uuid,
    workflowId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<Uuid> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}/first-status-for-role/${roleId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  get(
    requestContext: IRequestContext,
    workflowId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IWorkflowServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getAll(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IWorkflowServerModel[]> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/workflows`;
    const cacheKey = getProjectWorkflowsCacheKey(requestContext.projectId);
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, null, getValue, cacheExpiration, abortSignal);
  },

  getStepsUsage(
    requestContext: IRequestContext,
    workflowId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<WorkflowStepsUsageServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}/steps-usage`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getWorkflowsUsage(
    requestContext: IRequestContext,
    workflowIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<WorkflowsUsageServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/usage`;
    return restProvider.post(url, workflowIds, abortSignal, requestContext);
  },

  create(
    requestContext: IRequestContext,
    workflow: IWorkflowRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IWorkflowServerModel> {
    throwAwayWorkflowDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/workflows`;
    return restProvider.post(url, workflow, abortSignal, requestContext);
  },

  update(
    requestContext: IRequestContext,
    workflowId: Uuid,
    workflow: IWorkflowRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IWorkflowServerModel> {
    throwAwayWorkflowDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}`;
    return restProvider.put(url, workflow, abortSignal, requestContext);
  },

  archive(
    requestContext: IRequestContext,
    workflowId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    throwAwayWorkflowDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}/archive`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  restore(
    requestContext: IRequestContext,
    workflowId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    throwAwayWorkflowDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/${workflowId}/restore`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  validate(
    requestContext: IRequestContext,
    workflow: IWorkflowValidationRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IWorkflowValidationResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/workflows/validate`;
    return restProvider.post(url, workflow, abortSignal, requestContext);
  },
};
