import moize, { Options as MoizeOptions } from 'moize';
import { assert } from '../utils/assert.ts';

type MemoizedResult<TFunction extends AnyFunction> = TFunction & {
  readonly originalFunction: TFunction;
};

/**
 * Remembers all arguments and fc results for every call ever made, keeping objects and primitives in memory forever, thus draining RAM.
 * @param fc Memoized function.
 */
const allForever = <TFunction extends AnyFunction>(fc: TFunction): MemoizedResult<TFunction> => {
  return moize(fc, {
    maxSize: Number.POSITIVE_INFINITY,
  });
};

type SafeMoizeOptions = Pick<
  MoizeOptions,
  'isDeepEqual' | 'isPromise' | 'isSerialized' | 'isShallowEqual' | 'maxAge' | 'maxArgs' | 'maxSize'
>;

const maxOne = <TFunction extends AnyFunction>(
  fc: TFunction,
  options?: Omit<SafeMoizeOptions, 'maxSize' | 'maxAge'>,
): MemoizedResult<TFunction> => {
  return moize(fc, {
    ...options,
    maxSize: 1,
  });
};

const maxN = <TFunction extends AnyFunction>(
  fc: TFunction,
  maxSize: number,
  options?: Omit<SafeMoizeOptions, 'maxSize' | 'maxAge'>,
): MemoizedResult<TFunction> => {
  return moize(fc, {
    ...options,
    maxSize,
  });
};

const maxAge = <TFunction extends AnyFunction>(
  fc: TFunction,
  maxAgeMs: number,
  options?: Omit<SafeMoizeOptions, 'maxAge'>,
): MemoizedResult<TFunction> => {
  return moize(fc, {
    maxSize: Number.POSITIVE_INFINITY,
    ...options,
    maxAge: maxAgeMs,
  });
};

const maxNWithTransformedArgs = <TFunction extends AnyFunction>(
  fc: TFunction,
  transformArgs: (args: Parameters<TFunction>) => ReadonlyArray<unknown>,
  maxSize: number,
  options?: Omit<SafeMoizeOptions, 'maxArgs' | 'maxSize' | 'maxAge'>,
): MemoizedResult<TFunction> => {
  return moize(fc, {
    ...options,
    maxSize,
    transformArgs: transformArgs as (args: Parameters<TFunction>) => Array<unknown>,
  });
};

const maxAgeWithTransformedArgs = <TFunction extends AnyFunction>(
  fc: TFunction,
  transformArgs: (args: Parameters<TFunction>) => ReadonlyArray<unknown>,
  maxAgeMs: number,
  options?: Omit<SafeMoizeOptions, 'maxArgs' | 'maxSize'>,
): MemoizedResult<TFunction> => {
  return moize(fc, {
    ...options,
    maxAge: maxAgeMs,
    transformArgs: transformArgs as (args: Parameters<TFunction>) => Array<unknown>,
  });
};

/**
 * Remembers multiple arguments of any type. Previous arguments are automatically garbage-collected when no longer referenced elsewhere.
 * Requires the first argument to be of type object or function.
 * @param fc Memoized function.
 */
const weak = <TFunction extends (...args: [AnyObject, ...any[]]) => any>(
  fc: TFunction,
): TFunction => {
  type Node = {
    primitiveResults?: Map<any, any>;
    referenceResults?: WeakMap<any, any>;
    primitiveArgs?: Map<any, Node>;
    referenceArgs?: WeakMap<any, Node>;
  };

  const root: Node = {};
  const maxArgs = 10000;

  return ((...args) => {
    assert(
      args.length <= maxArgs,
      () =>
        `Maximum of ${maxArgs} arguments is supported. Was called with ${args.length} arguments.`,
    );

    let node = root;

    const last = args.length - 1;
    for (let i = 0; i < last; i += 1) {
      const arg = args[i];
      const primitive = arg === null || (typeof arg !== 'object' && typeof arg !== 'function');

      let argsMap: Map<any, Node> | WeakMap<any, Node> | null = null;

      if (primitive) {
        node.primitiveArgs ??= new Map();
        argsMap = node.primitiveArgs;
      } else {
        node.referenceArgs ??= new WeakMap();
        argsMap = node.referenceArgs;
      }

      const nextNode = argsMap.get(arg);
      if (nextNode) {
        node = nextNode;
      } else {
        node = {};
        argsMap.set(arg, node);
      }
    }

    const lastArg = args[last];
    const primitive =
      lastArg === null || (typeof lastArg !== 'object' && typeof lastArg !== 'function');

    let resultsMap: Map<any, any> | WeakMap<any, any> | null = null;

    if (primitive) {
      node.primitiveResults ??= new Map();
      resultsMap = node.primitiveResults;
    } else {
      node.referenceResults ??= new WeakMap();
      resultsMap = node.referenceResults;
    }

    const result = resultsMap.get(lastArg);
    if (result !== undefined || resultsMap.has(lastArg)) {
      return result;
    }

    const newResult = fc.apply(this, args);
    resultsMap.set(lastArg, newResult);
    return newResult;
  }) as TFunction;
};

export const memoize = {
  allForever,
  maxAge,
  maxAgeWithTransformedArgs,
  maxN,
  maxNWithTransformedArgs,
  maxOne,
  weak,
};
