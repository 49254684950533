import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAssetTypeRepository } from './interfaces/IAssetTypeRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { AssetTypeServerModel } from './serverModels/contentModels/AssetTypeServerModel.type.ts';
import { CompiledContentTypeServerModel } from './serverModels/contentModels/contentTypeServerModels.ts';

const compiledAssetTypeKeyBase = 'compiledAssetType';
const assetTypeKeyBase = 'assetType';
const oneMinuteInMs = 60 * 1000;

const getAssetTypeCacheKey = (projectId: Uuid | undefined, assetTypeId: Uuid) =>
  Cache.getKey(assetTypeKeyBase, projectId, assetTypeId);

const getCompiledAssetTypeCacheKey = (projectId: Uuid | undefined, assetTypeId: Uuid) =>
  Cache.getKey(compiledAssetTypeKeyBase, projectId, assetTypeId);

const clearCache = (projectId: Uuid | undefined, assetTypeId: Uuid) => {
  Cache.throwAway(getCompiledAssetTypeCacheKey(projectId, assetTypeId));
  Cache.throwAway(getAssetTypeCacheKey(projectId, assetTypeId));
};

const restProvider = createRestProvider(createAjaxWithCredentials());

export const assetTypeRepository: RepositoryWithContext<IAssetTypeRepository> = {
  getCompiledAssetType(
    requestContext: IRequestContext,
    assetTypeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<CompiledContentTypeServerModel> {
    const cacheKey = getCompiledAssetTypeCacheKey(requestContext.projectId, assetTypeId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset-type/${assetTypeId}`;
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, null, getValue, oneMinuteInMs, abortSignal);
  },

  getAssetType(
    requestContext: IRequestContext,
    assetTypeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<AssetTypeServerModel> {
    const cacheKey = getAssetTypeCacheKey(requestContext.projectId, assetTypeId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset-type/${assetTypeId}`;
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, null, getValue, oneMinuteInMs, abortSignal);
  },

  updateAssetType(
    requestContext: IRequestContext,
    assetType: AssetTypeServerModel,
    abortSignal?: AbortSignal,
  ): Promise<AssetTypeServerModel> {
    clearCache(requestContext.projectId, assetType._id);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset-type/${
      assetType._id
    }`;
    return restProvider.put(url, assetType, abortSignal, requestContext);
  },
};
