import { Cache } from '../../_shared/utils/cache.ts';

export const TaxonomyGroupsCacheKey = 'taxonomyGroups';
export const TaxonomyGroupCacheKey = 'taxonomyGroup';

export function getTaxonomyGroupsKeyByProjectId(projectId?: Uuid): string {
  return Cache.getKey(TaxonomyGroupsCacheKey, projectId);
}

export function getTaxonomyGroupKeyById(taxonomyGroupId: Uuid, projectId?: Uuid): string {
  return Cache.getKey(TaxonomyGroupsCacheKey, 'byId', taxonomyGroupId, projectId);
}

export function getTaxonomyGroupDummyKeyByProjectId(projectId?: Uuid): string {
  return Cache.getKey(TaxonomyGroupCacheKey, projectId, 'any');
}

export function touchTaxonomyGroupAndItsDependencies(
  projectId: Uuid | undefined,
  taxonomyGroupId: Uuid,
) {
  Cache.throwAway(Cache.getKey(getTaxonomyGroupDummyKeyByProjectId(projectId)));
  Cache.throwAway(getTaxonomyGroupKeyById(taxonomyGroupId, projectId));
}
