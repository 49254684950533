import { ListingServerResponseModel } from './ensureAllRequestedDataFetched.ts';

export const continueLoadingPredicateToAbortSignal = (
  continueLoading?: Predicate,
  abortSignal?: AbortSignal,
) => {
  const abortController = new AbortController();
  if (abortSignal) {
    abortSignal.onabort = () => abortController.abort();
  }

  const requestDecorator = async <TResponseData>(
    request: () => Promise<ListingServerResponseModel<TResponseData>>,
  ) => {
    const result = await request();

    if (continueLoading && !continueLoading()) {
      abortController.abort();
      return result;
    }

    return result;
  };

  return {
    requestDecorator,
    decoratedAbortSignal: abortController.signal,
  };
};
