import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IProjectContainerRepository } from './interfaces/IProjectContainerRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IApiStatusResponseModel } from './serverModels/IApiStatusResponseModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const projectContainerRepository: RepositoryWithContext<IProjectContainerRepository> = {
  getApiStatus: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IApiStatusResponseModel>> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/api-status`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
