import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  getSubscriptionAPIKeyBySubscriptionId,
  touchSubscriptionApiKey,
} from './cacheKeys/userCacheUtils.ts';
import { IApiKeysRepository } from './interfaces/IApiKeysRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IApiKeyExpirationServerModel } from './serverModels/IApiKeyExpirationServerModel.type.ts';
import { IApiKeyListingDataServerModel } from './serverModels/IApiKeyListingDataServerModel.type.ts';
import { IApiTokenDetailServerModel } from './serverModels/IApiTokenDetailServerModel.type.ts';
import { IApiTokenFilterServerModel } from './serverModels/IApiTokenFilterServerModel.type.ts';
import { ICreateApiTokenServerModel } from './serverModels/ICreateApiTokenServerModel.type.ts';
import { IUpdateApiTokenServerModel } from './serverModels/IUpdateApiTokenServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 10 });

export const apiKeysRepository: RepositoryWithContext<IApiKeysRepository> = {
  createApiKey: (
    requestContext: IRequestContext,
    apiKey: ICreateApiTokenServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys`;

    return restProvider.post(url, apiKey, abortSignal, requestContext);
  },

  generateSubscriptionApiKey: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    apiKey: ICreateApiTokenServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    touchSubscriptionApiKey(subscriptionId);
    const url = `${getUrlFactory().getDraftSubscriptionApiUrl(subscriptionId)}/key`;

    return restProvider.post(url, apiKey, abortSignal, requestContext);
  },

  getApiKey: (
    requestContext: IRequestContext,
    tokenSeedId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys/${tokenSeedId}`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getApiKeyListing: (
    requestContext: IRequestContext,
    filter: IApiTokenFilterServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IApiKeyListingDataServerModel>> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys/listing`;

    return restProvider.post(url, filter, abortSignal, requestContext);
  },

  getSubscriptionApiKeys: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<readonly IApiTokenDetailServerModel[]> => {
    const url = `${getUrlFactory().getDraftSubscriptionApiUrl(subscriptionId)}/key`;

    return Cache.cache(
      getSubscriptionAPIKeyBySubscriptionId(subscriptionId),
      [],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  regenerateApiKey: (
    requestContext: IRequestContext,
    tokenSeedId: Uuid,
    expiration: IApiKeyExpirationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys/${tokenSeedId}/regenerate`;

    return restProvider.post(url, expiration, abortSignal, requestContext);
  },

  regenerateSubscriptionApiKey: (
    requestContext: IRequestContext,
    subscriptionId: Uuid,
    tokenSeedId: Uuid,
    expiration: IApiKeyExpirationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    touchSubscriptionApiKey(subscriptionId);
    const url = `${getUrlFactory().getDraftSubscriptionApiUrl(
      subscriptionId,
    )}/key/${tokenSeedId}/regenerate`;

    return restProvider.post(url, expiration, abortSignal, requestContext);
  },

  revokeApiKey: (
    requestContext: IRequestContext,
    tokenSeedId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys/${tokenSeedId}/revoke`;

    return restProvider.delete(url, abortSignal, requestContext);
  },

  updateApiKey: (
    requestContext: IRequestContext,
    tokenSeedId: Uuid,
    apiToken: IUpdateApiTokenServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IApiTokenDetailServerModel> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/keys/${tokenSeedId}/update`;

    return restProvider.put(url, apiToken, abortSignal, requestContext);
  },
};
