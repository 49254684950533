import { Cache } from '../../_shared/utils/cache.ts';

export function getPeopleOnProjectKeyByProjectId(projectId?: Uuid): string {
  return Cache.getKey('people', projectId, 'all');
}

export const DummyPeopleCacheKey = Cache.getKey('people', 'any');

export function touchPeopleDependencies(): void {
  Cache.throwAway(DummyPeopleCacheKey);
}

export function getDummyPeopleOnProjectKeyByProjectId(projectId?: Uuid): string {
  return Cache.getKey('people', projectId, 'any');
}

export function touchPeopleOnProjectDependencies(projectId?: Uuid): void {
  Cache.throwAway(getDummyPeopleOnProjectKeyByProjectId(projectId));
}
