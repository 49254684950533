import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IPreviewConfigurationRepository } from './interfaces/IPreviewConfigurationRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IPreviewConfigurationServerModel } from './serverModels/IPreviewConfigurationServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const previewConfigurationRepository: RepositoryWithContext<IPreviewConfigurationRepository> =
  {
    get(
      requestContext: IRequestContext,
      projectId: Uuid,
      abortSignal?: AbortSignal,
    ): Promise<IPreviewConfigurationServerModel> {
      const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/preview-configuration`;
      return restProvider.get(url, null, abortSignal, requestContext);
    },

    save(
      requestContext: IRequestContext,
      projectId: Uuid,
      previewConfiguration: IPreviewConfigurationServerModel,
      abortSignal?: AbortSignal,
    ): Promise<IPreviewConfigurationServerModel> {
      const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/preview-configuration`;
      return restProvider.post(url, previewConfiguration, abortSignal, requestContext);
    },
  };
