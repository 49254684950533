import { TrackUserEventWelcomePageAllowedData } from '../../../../views/welcome/scripts/models/TrackUserEvent.type.ts';
import { TrackedEvent, WelcomePageTrackedEvent } from '../constants/trackedEvent.ts';
import { TrackUserEventAllowedData } from '../models/TrackUserEventData.ts';

export type TrackUserEventAllowedDataWithProject = TrackUserEventAllowedData & {
  readonly project: Uuid;
};

export type TrackUserEventDirectData =
  | TrackUserEventAllowedDataWithProject
  | TrackUserEventWelcomePageAllowedData;

export type UserEvent = TrackedEvent | WelcomePageTrackedEvent;

export type IEventTrackingListener = (
  eventName: UserEvent,
  metadata?: TrackUserEventDirectData,
) => void;

interface IEventTrackingService {
  readonly subscribe: (listener: IEventTrackingListener) => void;
  readonly unsubscribe: (listener: IEventTrackingListener) => void;
  readonly trackUserEvent: IEventTrackingListener;
}

const listeners: Set<IEventTrackingListener> = new Set<IEventTrackingListener>([]);

export const eventTrackingService: IEventTrackingService = {
  subscribe: (listener: IEventTrackingListener) => {
    listeners.add(listener);
  },

  unsubscribe: (listener: IEventTrackingListener) => {
    listeners.delete(listener);
  },

  trackUserEvent: (eventName, metadata) => {
    listeners.forEach((listener: IEventTrackingListener) => {
      listener(eventName, metadata);
    });
  },
};
