import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAddButtonPermissionsServerModel } from '../applications/webSpotlight/types/IAddButtonPermissionsServerModels.ts';
import { IWebSpotlightTreeOperationsCheckServerModel } from '../applications/webSpotlight/types/IWebSpotlightTreeActionsChekTypes.type.ts';
import { IWebSpotlightRepository } from './interfaces/IWebSpotlightRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IPageContentItemsIdsServerModel } from './serverModels/PageContentItemsIdsServerModel.type.ts';
import { IPreviewApiContentItemLastModificationDate } from './serverModels/PreviewApiContentItemLastModificationDate.type.ts';
import { ISubpagesIdsServerModel } from './serverModels/SubpageItemIdsServerModel.type.ts';
import { IWebSpotlightConfigurationServerModel } from './serverModels/WebSpotlightConfigurationServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const webSpotlightRepository: RepositoryWithContext<IWebSpotlightRepository> = {
  checkElementOperationsStatus(
    requestContext: IRequestContext,
    variantId: Uuid,
    itemId: Uuid,
    elementCodename: string,
    deliveryContentComponentId: Uuid | undefined,
    abortSignal?: AbortSignal,
  ): Promise<IAddButtonPermissionsServerModel> {
    let url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/web-spotlight/${variantId}/${itemId}/element-operations-status/${elementCodename}`;
    if (deliveryContentComponentId) {
      url += `/${deliveryContentComponentId}`;
    }

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  checkSubpagesOperationsStatus(
    requestContext: IRequestContext,
    variantId: Uuid,
    itemId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IWebSpotlightTreeOperationsCheckServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/web-spotlight/${variantId}/${itemId}/subpages-operations-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getItemLastModificationDateFromPreviewApi(
    requestContext: IRequestContext,
    variantCodename: string,
    contentItemCodename: string,
    abortSignal?: AbortSignal,
  ): Promise<IPreviewApiContentItemLastModificationDate> {
    const url = `${getUrlFactory().getDraftApiUrl()}/web-spotlight-preview/${
      requestContext.projectId
    }/preview-item-last-modified/${variantCodename}/${contentItemCodename}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getWebSpotlightConfiguration(
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IWebSpotlightConfigurationServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/web-spotlight/configuration`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getSubpagesIds(
    requestContext: IRequestContext,
    variantId: Uuid,
    contentItemIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<ISubpagesIdsServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/web-spotlight/${variantId}/subpages`;
    const query = {
      itemIds: contentItemIds,
    };
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  getPageContentItemsIds(
    requestContext: IRequestContext,
    variantId: Uuid,
    contentItemIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<IPageContentItemsIdsServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/web-spotlight/${variantId}/page-content-items`;
    const query = {
      itemIds: contentItemIds,
    };
    return restProvider.post(url, query, abortSignal, requestContext);
  },

  setWebSpotlightStatus(
    requestContext: IRequestContext,
    projectId: Uuid,
    enabled: boolean,
    abortSignal?: AbortSignal,
  ): Promise<IWebSpotlightConfigurationServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectId,
    )}/web-spotlight/set-web-spotlight-status`;
    return restProvider.put(url, { enabled }, abortSignal, requestContext);
  },
};
