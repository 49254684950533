import { createAjaxWithCredentials } from '../../../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../../../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../../../_shared/utils/urlFactory.ts';
import { RepositoryWithContext } from '../../../repositories/interfaces/repository.type.ts';
import { IAiGuidelinesRepository } from './IAiGuidelinesRepository.type.ts';
import {
  IAiGuidelinesRequestServerModel,
  IAiGuidelinesResponseServerModel,
} from './serverModels/AiGuidelinesServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const aiGuidelinesRepository: RepositoryWithContext<IAiGuidelinesRepository> = {
  createAiGuidelines: (
    requestContext: IRequestContext,
    aiGuidelinesRequest: IAiGuidelinesRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAiGuidelinesResponseServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/ai-guidelines`;
    return restProvider.post(url, aiGuidelinesRequest, abortSignal, requestContext);
  },
  getAllAiGuidelines: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IAiGuidelinesResponseServerModel>> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/ai-guidelines`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
  updateAiGuidelines: (
    requestContext: IRequestContext,
    id: Uuid,
    aiGuidelinesRequest: IAiGuidelinesRequestServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IAiGuidelinesResponseServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/ai-guidelines/${id}`;
    return restProvider.put(url, aiGuidelinesRequest, abortSignal, requestContext);
  },
};
