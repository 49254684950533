import Immutable from 'immutable';
import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  DummyPeopleCacheKey,
  getDummyPeopleOnProjectKeyByProjectId,
  getPeopleOnProjectKeyByProjectId,
  touchPeopleDependencies,
  touchPeopleOnProjectDependencies,
} from './cacheKeys/peopleCacheUtils.ts';
import { touchProjectsDependencies } from './cacheKeys/projectCacheUtils.ts';
import {
  DummySubscriptionsKey,
  touchSubscriptionAndItsDependencies,
} from './cacheKeys/subscriptionCacheUtils.ts';
import { IPeopleRepository } from './interfaces/IPeopleRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { IProjectContainerActiveUserSeverModel } from './serverModels/IProjectContainerActiveUserSeverModel.type.ts';
import { IUserToInviteResponseServerModel } from './serverModels/InviteUserServerModel.type.ts';
import {
  ICollectionGroupServerModel,
  IProjectContributorServerModel,
} from './serverModels/ProjectDetailsServerModel.type.ts';
import { ISubscriptionActiveUsersServerModel } from './serverModels/SubscriptionUsageServerModel.type.ts';
import { IUpdateUserRolesServerModel } from './serverModels/UserDetailServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 10 });

const clearCacheOnProjectChanges = (projectId: Uuid | undefined) => {
  touchPeopleOnProjectDependencies(projectId);
  touchPeopleDependencies();
  touchProjectsDependencies();
};

const clearCacheOnSubscriptionChanges = (subscriptionId: Uuid) => {
  touchPeopleDependencies();
  touchProjectsDependencies();
  touchSubscriptionAndItsDependencies(subscriptionId);
};

const triggerUserAction = (
  requestContext: IRequestContext,
  userId: Uuid,
  actionName: string,
  abortSignal?: AbortSignal,
): Promise<void> => {
  const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
    requestContext.projectId,
  )}/user/${encodeURIComponent(userId)}/${actionName}`;
  return restProvider.put(url, null, abortSignal, requestContext).then(
    () => clearCacheOnProjectChanges(requestContext.projectId),
    (x) => {
      clearCacheOnProjectChanges(requestContext.projectId);
      throw x;
    },
  );
};

const triggerUserActionOnProject = (
  requestContext: IRequestContext,
  userId: UserId,
  subscriptionId: Uuid,
  projectId: Uuid,
  actionName: string,
  abortSignal?: AbortSignal,
): Promise<void> => {
  const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
    projectId,
  )}/user/${encodeURIComponent(userId)}/${actionName}`;
  return restProvider.put(url, null, abortSignal, requestContext).then(
    () => clearCacheOnSubscriptionChanges(subscriptionId),
    (x) => {
      clearCacheOnSubscriptionChanges(subscriptionId);
      throw x;
    },
  );
};

const triggerUserActionOnAllSubscriptionProjects = (
  requestContext: IRequestContext,
  userId: UserId,
  subscriptionId: Uuid,
  actionName: string,
  abortSignal?: AbortSignal,
): Promise<void> => {
  const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/users/${userId}/project-users/${actionName}`;
  return restProvider.put(url, null, abortSignal, requestContext).then(
    () => clearCacheOnSubscriptionChanges(subscriptionId),
    (x) => {
      clearCacheOnSubscriptionChanges(subscriptionId);
      throw x;
    },
  );
};

export const peopleRepository: RepositoryWithContext<IPeopleRepository> = {
  getProjectContributors: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<Array<IProjectContributorServerModel>> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/people`;
    return Cache.cache(
      getPeopleOnProjectKeyByProjectId(requestContext.projectId),
      [
        getDummyPeopleOnProjectKeyByProjectId(requestContext.projectId),
        DummySubscriptionsKey,
        DummyPeopleCacheKey,
      ],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  getPeopleForInvitation: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<Array<IUserToInviteResponseServerModel>> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/users-to-invite`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getPeopleIdsCountedInUsage: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ISubscriptionActiveUsersServerModel> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/subscription-active-users`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getProjectContainerActiveUsers: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<Array<IProjectContainerActiveUserSeverModel>> => {
    const url = `${getUrlFactory().getDraftProjectContainerApiUrl(
      requestContext.projectContainerId,
    )}/users/active-users`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  updateUserRoles: async (
    requestContext: IRequestContext,
    userId: Uuid,
    collectionGroups: ReadonlyArray<ICollectionGroupServerModel>,
    abortSignal?: AbortSignal,
  ): Promise<IProjectContributorServerModel> => {
    const data: IUpdateUserRolesServerModel = {
      collectionGroups,
    };

    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/people/${encodeURIComponent(userId)}/updateroles`;

    try {
      const user = await restProvider.post(url, data, abortSignal, requestContext);
      clearCacheOnProjectChanges(requestContext.projectId);
      return user;
    } catch (x) {
      clearCacheOnProjectChanges(requestContext.projectId);

      throw x;
    }
  },

  activateContributor: (
    requestContext: IRequestContext,
    userId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserAction(requestContext, userId, 'activate', abortSignal);
  },

  deactivateContributor: (
    requestContext: IRequestContext,
    userId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserAction(requestContext, userId, 'deactivate', abortSignal);
  },

  activateContributorOnProject: (
    requestContext: IRequestContext,
    userId: UserId,
    subscriptionId: Uuid,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserActionOnProject(
      requestContext,
      userId,
      subscriptionId,
      projectId,
      'activate',
      abortSignal,
    );
  },

  deactivateContributorOnProject: (
    requestContext: IRequestContext,
    userId: UserId,
    subscriptionId: Uuid,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserActionOnProject(
      requestContext,
      userId,
      subscriptionId,
      projectId,
      'deactivate',
      abortSignal,
    );
  },

  activateContributorOnAllSubscriptionProjects: (
    requestContext: IRequestContext,
    userId: UserId,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserActionOnAllSubscriptionProjects(
      requestContext,
      userId,
      subscriptionId,
      'activate',
      abortSignal,
    );
  },

  deactivateContributorOnAllSubscriptionProjects: (
    requestContext: IRequestContext,
    userId: UserId,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    return triggerUserActionOnAllSubscriptionProjects(
      requestContext,
      userId,
      subscriptionId,
      'deactivate',
      abortSignal,
    );
  },

  deactivateContributorsOnSubscriptionProjects: async (
    requestContext: IRequestContext,
    userIds: Immutable.Set<UserId>,
    subscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/subscription/${subscriptionId}/users/deactivate`;

    await restProvider.post(url, userIds, abortSignal, requestContext);
    touchPeopleDependencies();
    touchProjectsDependencies();
  },

  revokeInvite: (
    requestContext: IRequestContext,
    userId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/user/${encodeURIComponent(userId)}`;
    return restProvider.delete(url, abortSignal, requestContext).then(
      () => clearCacheOnProjectChanges(requestContext.projectId),
      (x) => {
        clearCacheOnProjectChanges(requestContext.projectId);
        throw x;
      },
    );
  },

  revokeInviteToProject: (
    requestContext: IRequestContext,
    userId: UserId,
    subscriptionId: Uuid,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      projectId,
    )}/user/${encodeURIComponent(userId)}`;
    return restProvider.delete(url, abortSignal, requestContext).then(
      () => clearCacheOnSubscriptionChanges(subscriptionId),
      (x) => {
        clearCacheOnSubscriptionChanges(subscriptionId);
        throw x;
      },
    );
  },
};
