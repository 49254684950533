import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  touchPeopleDependencies,
  touchPeopleOnProjectDependencies,
} from './cacheKeys/peopleCacheUtils.ts';
import { touchProject, touchProjectsDependencies } from './cacheKeys/projectCacheUtils.ts';
import { IInviteRepository } from './interfaces/IInviteRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IBulkInviteUsersServerModel,
  IResendInvitationResponseServerModel,
  IResendInvitationServerModel,
} from './serverModels/InviteUserServerModel.type.ts';
import { IBulkProjectContributorServerModel } from './serverModels/ProjectDetailsServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

function clearCache(projectId?: Uuid): void {
  touchPeopleDependencies();
  touchProjectsDependencies();
  touchPeopleOnProjectDependencies(projectId);
  touchProject(projectId);
}

export const inviteRepository: RepositoryWithContext<IInviteRepository> = {
  async sendBulkInvite(
    requestContext: IRequestContext,
    invitation: IBulkInviteUsersServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IBulkProjectContributorServerModel> {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/invite/bulk`;
    const invitationServerModel = await restProvider.post(
      url,
      invitation,
      abortSignal,
      requestContext,
    );
    clearCache(requestContext.projectId);
    return invitationServerModel;
  },

  async resendInvite(
    requestContext: IRequestContext,
    invitation: IResendInvitationServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IResendInvitationResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/invite/resend`;
    return await restProvider.post(url, invitation, abortSignal, requestContext);
  },

  async resendInviteToProject(
    requestContext: IRequestContext,
    invitation: IResendInvitationServerModel,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IResendInvitationResponseServerModel> {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/invite/resend`;
    return await restProvider.post(url, invitation, abortSignal, requestContext);
  },
};
