import { InvariantException } from '@kontent-ai/errors';

type ContinuationFilter = {
  continuationToken: ContinuationToken;
  maxItemsCount: number;
};

type AccumulatedDataResult<TData> = {
  readonly data: ReadonlyArray<TData>;
  readonly wasDataCountLimitExceeded: boolean;
};

type ContinuationMethodResult<TData> = {
  readonly continuationToken: ContinuationToken;
  readonly data: ReadonlyArray<TData>;
};

type MethodWithContinuation<TData> = (
  continuationFilter: ContinuationFilter,
) => Promise<ContinuationMethodResult<TData>>;

export type DataFilter<TData> = (dataChunk: ReadonlyArray<TData>) => Promise<ReadonlyArray<TData>>;

const NoFilter = <TData>(dataChunk: ReadonlyArray<TData>) => Promise.resolve(dataChunk);

export const loadContinuousData = async <TData>(
  loader: MethodWithContinuation<TData>,
  dataCountLimit: number,
  continueLoading: Predicate,
  dataFilter: DataFilter<TData> = NoFilter,
): Promise<AccumulatedDataResult<TData>> => {
  let accumulatedData: ReadonlyArray<TData> = [];
  let continuationToken: ContinuationToken = null;
  let loadNextChunk: boolean | null = null;

  if (dataCountLimit <= 0) {
    throw InvariantException(
      'loadContinuousData.ts: a value greater than 0 has to be specified as dataCountLimit',
    );
  }

  do {
    const continuationFilter: ContinuationFilter = {
      continuationToken,
      // the count needs to be at least 1 at all times, since 0 means "all", also it allows detection of limit excess
      maxItemsCount: dataCountLimit - accumulatedData.length + 1,
    };
    const response = await loader(continuationFilter);

    continuationToken = response.continuationToken;
    accumulatedData = accumulatedData.concat(await dataFilter(response.data));

    loadNextChunk =
      continueLoading() && // receiving more data is still desirable
      !!continuationToken && // there are more data on the server
      accumulatedData.length < dataCountLimit; // total count of expected data is not yet exceeded
  } while (loadNextChunk);

  const wasDataCountLimitExceeded =
    accumulatedData.length > dataCountLimit ||
    (accumulatedData.length === dataCountLimit && !!continuationToken);

  return {
    wasDataCountLimitExceeded,
    data: accumulatedData.slice(0, dataCountLimit),
  };
};
