import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ISitemapRepository } from './interfaces/ISitemapRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ISitemapServerModel } from './serverModels/contentModels/SitemapServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const sitemapRepository: RepositoryWithContext<ISitemapRepository> = {
  getSitemap(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ISitemapServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/sitemap`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  createSitemap(
    requestContext: IRequestContext,
    sitemap: ISitemapServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ISitemapServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/sitemap`;
    return restProvider.post(url, sitemap, abortSignal, requestContext);
  },

  updateSitemap(
    requestContext: IRequestContext,
    sitemap: ISitemapServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ISitemapServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/sitemap`;
    return restProvider.put(url, sitemap, abortSignal, requestContext);
  },
};
