import { Cache } from '../../_shared/utils/cache.ts';
import { isUuid } from '../../_shared/utils/validation/typeValidators.ts';
import { touchPeopleOnProjectDependencies } from './peopleCacheUtils.ts';

export const ProjectsKey = Cache.getKey('projects', 'all');
export const DummyProjectsKey = Cache.getKey('projects', 'any');

export function getProjectKeyByProjectId(projectId?: Uuid): string {
  return Cache.getKey('project', 'byId', projectId);
}

export function getLanguagesByProjectId(projectId?: Uuid) {
  return Cache.getKey('projectLanguages', projectId);
}

export function touchProjectsDependencies(): void {
  Cache.throwAway(DummyProjectsKey);
}

export function touchProject(projectId?: Uuid): void {
  Cache.throwAway(getProjectKeyByProjectId(projectId));
}

export function touchLanguages(projectId?: Uuid): void {
  Cache.throwAway(getLanguagesByProjectId(projectId));
}

export function touchProjectsDependenciesAndSpecificProject(projectId?: Uuid): void {
  touchProjectsDependencies();
  if (isUuid(projectId)) {
    touchProject(projectId);
  }
  touchPeopleOnProjectDependencies(projectId);
}
