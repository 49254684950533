import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  DummyProjectsKey,
  getLanguagesByProjectId,
  touchLanguages,
} from './cacheKeys/projectCacheUtils.ts';
import { ILanguageRepository } from './interfaces/ILanguageRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ILanguageUsagesServerModel,
  ILanguagesServerModel,
} from './serverModels/ProjectLanguagesServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 10 });

export const languageRepository: RepositoryWithContext<ILanguageRepository> = {
  getLanguages(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ILanguagesServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/languages`;
    const getLanguages = () => restProvider.get(url, null, abortSignal, requestContext);
    return Cache.cache(
      getLanguagesByProjectId(requestContext.projectId),
      null,
      getLanguages,
      cacheExpiration,
      abortSignal,
    );
  },

  getLanguagesForProject(
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ILanguagesServerModel> {
    return this.getLanguages({ ...requestContext, projectId }, abortSignal);
  },

  async updateLanguages(
    requestContext: IRequestContext,
    projectLanguages: ILanguagesServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ILanguagesServerModel> {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/languages`;
    try {
      const languages = await restProvider.put(url, projectLanguages, abortSignal, requestContext);
      touchLanguages(requestContext.projectId);
      return languages;
    } catch (e) {
      touchLanguages(requestContext.projectId);
      throw e;
    }
  },

  getLanguageUsages(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ILanguageUsagesServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/languages/usage`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
