export type ListingServerResponseModel<TData> = Readonly<{
  data: ReadonlyArray<TData>;
  continuationToken: ContinuationToken;
}>;

export type ListingRequestOptionsModel = Readonly<{
  maxItemsCount: number;
  continuationToken?: ContinuationToken;
}>;

type DataFetcher<TResponseData> = (
  requestOptions: ListingRequestOptionsModel,
) => Promise<ListingServerResponseModel<TResponseData>>;

export const ensureAllRequestedDataFetched = async <TResponseData>(
  fetcher: DataFetcher<TResponseData>,
  requestOptions: ListingRequestOptionsModel,
  abortSignal?: AbortSignal,
): Promise<ListingServerResponseModel<TResponseData>> => {
  const currentRequestOptions: Mutable<ListingRequestOptionsModel> = { ...requestOptions };
  let allData = new Array<TResponseData>();
  do {
    const result = await fetcher(currentRequestOptions);
    currentRequestOptions.continuationToken = result.continuationToken;
    if (result.data && result.data.length > 0) {
      currentRequestOptions.maxItemsCount -= result.data.length;
      allData = allData.concat(result.data);
    }
  } while (
    currentRequestOptions.continuationToken &&
    currentRequestOptions.maxItemsCount > 0 &&
    !abortSignal?.aborted
  );

  return {
    data: allData,
    continuationToken: currentRequestOptions.continuationToken,
  };
};
