const { IncrementalBuildDelivery } = require('./incrementalBuildDelivery');

const buildConfigDefault = {
  draftApiUrl: 'https://app.devkontentmasters.com',
  incrementalBuildDelivery: IncrementalBuildDelivery.LiveReload,
  openLocalUrlOnStart: false,
};

const getLocalConfig = () => {
  try {
    const { buildConfig, clientConfig, envConfig } = require('./config.local.js');
    return {
      buildConfig: {
        ...buildConfigDefault,
        ...buildConfig,
      },
      clientConfig,
      envConfig,
    };
  } catch {
    return {
      buildConfig: buildConfigDefault,
      clientConfig: {},
      envConfig: {},
    };
  }
};

module.exports = { getLocalConfig };
