import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IAssetFolderRepository } from './interfaces/IAssetFolderRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IAssetFolderArchiveResultServerModel,
  IAssetFolderRestoreRequestServerModel,
  IAssetFolderRestoreResultServerModel,
} from './serverModels/AssetFolderServerModel.type.ts';
import { ITaxonomyGroupServerModel } from './serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyPatchOperation,
  createTaxonomyPatchDocument,
} from './utils/createTaxonomyPatchDocument.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const assetFolderRepository: RepositoryWithContext<IAssetFolderRepository> = {
  archive(
    requestContext: IRequestContext,
    folderId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IAssetFolderArchiveResultServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset-folder/${folderId}/archive`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  getAll(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset-folder`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  patch(
    requestContext: IRequestContext,
    operation: ITaxonomyPatchOperation,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const patchDocument = createTaxonomyPatchDocument(operation);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/asset-folder`;
    return restProvider.patch(url, patchDocument, abortSignal, requestContext);
  },

  restore(
    requestContext: IRequestContext,
    foldersIds: ReadonlyArray<Uuid>,
    assetsIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetFolderRestoreResultServerModel> {
    const data: IAssetFolderRestoreRequestServerModel = {
      assetsIds,
      foldersIds,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/asset-folder/restore`;
    return restProvider.put(url, data, abortSignal, requestContext);
  },
};
