import { Cache } from '../../_shared/utils/cache.ts';

export const DummySubscriptionsKey = Cache.getKey('subscriptions', 'any');

export function getSubscriptionBillingHistoryBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionBillingHistory', 'byId', subscriptionId);
}

export function getSubscriptionUsageKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionUsage', 'byId', subscriptionId);
}

export function getSubscriptionUsersKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionUsers', 'byId', subscriptionId);
}

export function getSubscriptionTransactionsKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionTransactions', 'byId', subscriptionId);
}

export function getSubscriptionBillingInformationKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionBillingInformation', 'byId', subscriptionId);
}

export function getSubscriptionBalanceKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionBalance', 'byId', subscriptionId);
}

export function getSubscriptionAdminsKdyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('admins', 'byId', subscriptionId);
}

export function getSubscriptionCurrentSpendingReportBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionCurrentSpending', 'byId', subscriptionId);
}

export function getAdministratedSubscriptions(): string {
  return Cache.getKey('getAdministratedSubscriptions', 'all');
}

export function getSubscriptionUsersUsageKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('subscriptionUsersUsage', 'byId', subscriptionId);
}

export function getPropertiesKeyBySubscriptionId(subscriptionId: Uuid): string {
  return Cache.getKey('getProperties', 'byId', subscriptionId);
}

export function touchGetAdministratedSubscriptions() {
  Cache.throwAway(getAdministratedSubscriptions());
}

export function touchSubscriptionAndItsDependencies(subscriptionId?: Uuid): void {
  Cache.throwAway(DummySubscriptionsKey);
  touchGetAdministratedSubscriptions();

  if (subscriptionId) {
    Cache.throwAway(getSubscriptionAdminsKdyBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionUsageKeyBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionUsersKeyBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionBillingHistoryBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionCurrentSpendingReportBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionBillingInformationKeyBySubscriptionId(subscriptionId));
    Cache.throwAway(getSubscriptionUsersUsageKeyBySubscriptionId(subscriptionId));
  }
}
