import { notNull } from '@kontent-ai/utils';

function createQueryParameter(name: string, value: QueryParamValue): string {
  if (Array.isArray(value)) {
    // Duplicate query parameter for each array items
    return value.map((v) => createQueryParameter(name, v)).join('&');
  }

  if (typeof value === 'object' && value !== null && value !== undefined) {
    throw new Error(
      `Unable to insert object "${JSON.stringify(value)}" into a query string parameter value.`,
    );
  }

  if (
    name !== '' &&
    `${value}` !== '' &&
    name !== null &&
    name !== undefined &&
    value !== null &&
    value !== undefined
  ) {
    return `${name}=${encodeURIComponent(value)}`;
  }

  return '';
}

type QueryParamValue = string | string[] | boolean | number | QueryStringParams | null | undefined;
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export type QueryStringParams = { [key: string]: QueryParamValue };

/***
 * @param params an object in format "paramName: paramValue"
 */
export function buildUrlQueryString(params: QueryStringParams): string {
  const result = Object.entries(params)
    .map(([key, value]) => createQueryParameter(key, value) || null)
    .filter(notNull)
    .join('&');

  return result ? `?${result}` : result;
}
