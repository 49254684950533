import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IFilterRepository } from './interfaces/IFilterRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  IFilterServerModel,
  IFilterUpdateServerModel,
  IFiltersServerModel,
} from './serverModels/FilterServerModels.type.ts';

const cacheExpiration = getMilliseconds({ hours: 1 });
const cacheKeyBase = 'filter';

const throwAwayFilterCache = (projectId?: Uuid) => {
  const cacheKey = Cache.getKey(cacheKeyBase, projectId);
  Cache.throwAway(cacheKey);
};

const restProvider = createRestProvider(createAjaxWithCredentials());

export const filterRepository: RepositoryWithContext<IFilterRepository> = {
  createFilter: (
    requestContext: IRequestContext,
    filter: IFilterUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IFilterServerModel> => {
    throwAwayFilterCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/filters/`;

    return restProvider.post(url, filter, abortSignal, requestContext);
  },
  createLinkedFilter: (
    requestContext: IRequestContext,
    filter: IFilterUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IFilterServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/filters/linked`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },
  deleteFilter: (
    requestContext: IRequestContext,
    filterId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    throwAwayFilterCache(requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/filters/${filterId}`;

    return restProvider.delete(url, abortSignal, requestContext);
  },
  getLinkedFilter: (
    requestContext: IRequestContext,
    projectId: Uuid,
    filterId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IFilterServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/filters/linked/${filterId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
  getFilters: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IFiltersServerModel> => {
    const cacheKey = Cache.getKey(cacheKeyBase, requestContext.projectId);
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/filters/`;

    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, [], getValue, cacheExpiration, abortSignal);
  },
  restoreFilter: (
    requestContext: IRequestContext,
    filterId: Uuid | null,
    abortSignal?: AbortSignal,
  ): Promise<IFilterServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/filters/${filterId}/restore`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },
  updateFilter: (
    requestContext: IRequestContext,
    filterId: Uuid,
    filter: IFilterUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IFilterServerModel> => {
    throwAwayFilterCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/filters/${filterId}`;
    return restProvider.put(url, filter, abortSignal, requestContext);
  },
};
