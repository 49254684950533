import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IRelationRepository } from './interfaces/IRelationRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const relationRepository: RepositoryWithContext<IRelationRepository> = {
  getDefaultRootItemId: (requestContext: IRequestContext, abortSignal?: AbortSignal) => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/relations/home`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
  setDefaultRootItemId: (
    requestContext: IRequestContext,
    itemId: Uuid,
    abortSignal?: AbortSignal,
  ) => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/relations/home`;
    return restProvider.post(url, { itemId }, abortSignal, requestContext);
  },
};
