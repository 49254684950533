import { createAjaxWithCredentials } from '../../../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../../../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../../../_shared/utils/urlFactory.ts';
import { ICreateCommentThreadRequestModel } from '../../../repositories/interfaces/ICommentRepository.type.ts';
import { RepositoryWithContext } from '../../../repositories/interfaces/repository.type.ts';
import { ICommentThreadServerModel } from '../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import { IAiCommentRepository } from './IAiCommentRepository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const aiCommentRepository: RepositoryWithContext<IAiCommentRepository> = {
  createAiCommentThread(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    createCommentThreadRequestModel: ICreateCommentThreadRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/ai-comment-thread`;
    return restProvider.post(url, createCommentThreadRequestModel, abortSignal, requestContext);
  },
};
