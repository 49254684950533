/**
 @constant
 @type {Record<'NoAction' | 'LiveReload', 'no-action' | 'live-reload'>}
 */
const IncrementalBuildDelivery = {
  NoAction: 'no-action',
  LiveReload: 'live-reload',
};

module.exports = { IncrementalBuildDelivery };
