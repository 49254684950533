import { createAjaxWithCredentials } from '../../../../../_shared/utils/ajax.ts';
import { createRestProvider } from '../../../../../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../../../../../_shared/utils/urlFactory.ts';
import { RepositoryWithContext } from '../../../../../repositories/interfaces/repository.type.ts';
import { RecommendedLinkedItemsRepository } from './RecommendedLinkedItemsRepository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const recommendedLinkedItemsRepository: RepositoryWithContext<RecommendedLinkedItemsRepository> =
  {
    getRecommendedLinkedItems(requestContext, itemId, variantId, filter, abortSignal?) {
      const url = `${getUrlFactory().getDraftProjectApiUrl(
        requestContext.projectId,
      )}/recommended-linked-items/${itemId}/${variantId}`;
      return restProvider.post(url, filter, abortSignal, requestContext);
    },
  };
