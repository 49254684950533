import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { QueryStringParams } from '../_shared/utils/buildUrlQueryString.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { getContentTypeSnippetKeyByProjectId } from './cacheKeys/itemCacheUtils.ts';
import { IContentTypeSnippetRepository } from './interfaces/IContentTypeSnippetRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { TypeListOperationResultServerModel } from './serverModels/contentModels/contentTypeServerModels.ts';
import {
  ContentTypeSnippetServerModel,
  ContentTypeSnippetsServerModel,
  CreateNewContentTypeSnippetServerModel,
  SnippetUsageServerModel,
} from './serverModels/contentModels/contentTypeSnippetServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const snippetKeyBase = 'contentTypeSnippet';
const oneSecondInMs = 1000;

export const invalidateContentTypeSnippetCache = (projectId: Uuid | undefined) => {
  const anyContentTypeSnippetCacheKey = getContentTypeSnippetKeyByProjectId(projectId);
  Cache.throwAway(anyContentTypeSnippetCacheKey);
};

export const contentTypeSnippetRepository: RepositoryWithContext<IContentTypeSnippetRepository> = {
  getContentTypeSnippets(
    requestContext: IRequestContext,
    queryParams: QueryStringParams | null,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetsServerModel> {
    const cacheKey = Cache.getKey(
      snippetKeyBase,
      requestContext.projectId,
      JSON.stringify(queryParams),
    );
    const dummyKeys = [Cache.getKey(snippetKeyBase, requestContext.projectId, 'any')];
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type-snippet`;
    const getValue = () => restProvider.get(url, queryParams, abortSignal, requestContext);

    return Cache.cache(cacheKey, dummyKeys, getValue, oneSecondInMs, abortSignal);
  },

  getContentTypeSnippet(
    requestContext: IRequestContext,
    contentTypeSnippetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/${contentTypeSnippetId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getSnippetUsage(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<SnippetUsageServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/snippet-usage`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  createContentTypeSnippet(
    requestContext: IRequestContext,
    contentTypeSnippet: CreateNewContentTypeSnippetServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type-snippet`;
    return restProvider.post(url, contentTypeSnippet, abortSignal, requestContext);
  },

  updateContentTypeSnippet(
    requestContext: IRequestContext,
    contentTypeSnippet: ContentTypeSnippetServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type-snippet/${
      contentTypeSnippet._id
    }`;
    return restProvider.put(url, contentTypeSnippet, abortSignal, requestContext);
  },

  archiveContentTypeSnippet(
    requestContext: IRequestContext,
    contentTypeSnippetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const archiveContentTypeSnippetBody = '{"archived": true}';
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/${contentTypeSnippetId}/archive`;
    return restProvider.put(url, archiveContentTypeSnippetBody, abortSignal, requestContext);
  },

  restoreContentTypeSnippet(
    requestContext: IRequestContext,
    contentTypeSnippetId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeSnippetServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const restoreContentTypeSnippetBody = '{"archived": false}';
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/${contentTypeSnippetId}/archive`;
    return restProvider.put(url, restoreContentTypeSnippetBody, abortSignal, requestContext);
  },

  archiveContentTypeSnippets(
    requestContext: IRequestContext,
    typeIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<TypeListOperationResultServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const archiveContentTypeSnippetsBody = {
      typeIds,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/archive`;
    return restProvider.put(url, archiveContentTypeSnippetsBody, abortSignal, requestContext);
  },

  restoreContentTypeSnippets(
    requestContext: IRequestContext,
    typeIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<TypeListOperationResultServerModel> {
    invalidateContentTypeSnippetCache(requestContext.projectId);

    const archiveContentTypeSnippetsBody = {
      typeIds,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type-snippet/restore`;
    return restProvider.put(url, archiveContentTypeSnippetsBody, abortSignal, requestContext);
  },
};
