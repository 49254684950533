import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  ICommentRepository,
  ICommentRequestModel,
  ICreateCommentThreadRequestModel,
  ISuggestionRequestModel,
} from './interfaces/ICommentRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ICommentThreadServerModel } from './serverModels/CommentThreadServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const commentRepository: RepositoryWithContext<ICommentRepository> = {
  getAll(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    includeOnlyOpen?: boolean,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel[]> {
    const queryParams = new URLSearchParams();

    if (includeOnlyOpen) {
      queryParams.set('includeOnlyOpen', 'true');
    }

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread?${queryParams.toString()}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  createCommentThread(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    createCommentThreadRequestModel: ICreateCommentThreadRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread`;
    return restProvider.post(url, createCommentThreadRequestModel, abortSignal, requestContext);
  },

  addComment(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    commentRequestModel: ICommentRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/comment`;

    return restProvider.post(url, commentRequestModel, abortSignal, requestContext);
  },

  editComment(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    commentId: Uuid,
    commentRequestModel: ICommentRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/comment/${commentId}`;
    return restProvider.put(url, commentRequestModel, abortSignal, requestContext);
  },

  addSuggestion(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    suggestionRequestModel: ISuggestionRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/suggestion`;
    return restProvider.post(url, suggestionRequestModel, abortSignal, requestContext);
  },

  editSuggestion(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    commentId: Uuid,
    suggestionRequestModel: ISuggestionRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<ICommentThreadServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/suggestion/${commentId}`;
    return restProvider.put(url, suggestionRequestModel, abortSignal, requestContext);
  },

  markCommentThreadAsResolved(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/mark-as-resolved`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  markCommentThreadAsUnresolved(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/mark-as-unresolved`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  markSuggestionAsApproved(
    requestContext: IRequestContext,
    itemId: Uuid,
    variantId: Uuid,
    commentThreadId: Uuid,
    suggestionId: Uuid,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/item/${itemId}/variant/${variantId}/comment-thread/${commentThreadId}/suggestion/${suggestionId}/mark-as-approved`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },
};
