import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { touchProjectsDependenciesAndSpecificProject } from './cacheKeys/projectCacheUtils.ts';
import { IRoleRepository } from './interfaces/IRoleRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  INormalizedProjectUserCollectionGroupsServerModel,
  IRoleServerModel,
  IRoleUpdateServerModel,
} from './serverModels/IRoleServerModel.type.ts';

const cacheExpiration = getMilliseconds({ hours: 1 });
const cacheKeyBase = 'roles';
const getProjectRolesInvalidationKey = (projectId?: Uuid) => Cache.getKey(cacheKeyBase, projectId);

export const anyRoleCacheKey = Cache.getKey(cacheKeyBase, 'any');

const throwAwayRoleDependentCache = (projectId?: Uuid) => {
  Cache.throwAway(getProjectRolesInvalidationKey(projectId));
  Cache.throwAway(anyRoleCacheKey);
  touchProjectsDependenciesAndSpecificProject(projectId);
};

const restProvider = createRestProvider(createAjaxWithCredentials());

export const roleRepository: RepositoryWithContext<IRoleRepository> = {
  getRoles: (
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IRoleServerModel[]> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/roles`;

    const cacheKey = Cache.getKey(cacheKeyBase, requestContext.projectId);
    const invalidationKeys = [getProjectRolesInvalidationKey(requestContext.projectId)];
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, invalidationKeys, getValue, cacheExpiration, abortSignal);
  },

  getProjectRoles: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IRoleServerModel[]> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/roles`;

    const cacheKey = Cache.getKey(cacheKeyBase, projectId);
    const invalidationKeys = [getProjectRolesInvalidationKey(projectId)];
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, invalidationKeys, getValue, cacheExpiration, abortSignal);
  },

  getRole: (
    requestContext: IRequestContext,
    roleId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IRoleServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/roles/${roleId}`;

    const cacheKey = Cache.getKey(cacheKeyBase, requestContext.projectId, roleId);
    const invalidationKeys = [getProjectRolesInvalidationKey(requestContext.projectId)];
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, invalidationKeys, getValue, cacheExpiration, abortSignal);
  },

  getNormalizedRoleWithSettings: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<INormalizedProjectUserCollectionGroupsServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/roles/current/normalized`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },

  createRole: (
    requestContext: IRequestContext,
    role: IRoleUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IRoleServerModel> => {
    throwAwayRoleDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/roles`;
    return restProvider.post(url, role, abortSignal, requestContext);
  },

  updateRole: (
    requestContext: IRequestContext,
    roleId: Uuid,
    role: IRoleUpdateServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IRoleServerModel> => {
    throwAwayRoleDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/roles/${roleId}`;
    return restProvider.put(url, role, abortSignal, requestContext);
  },

  deleteRole: (
    requestContext: IRequestContext,
    roleId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    throwAwayRoleDependentCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/roles/${roleId}`;
    return restProvider.delete(url, abortSignal, requestContext);
  },
};
