/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { isString } from './stringUtils.ts';

const primaryCache: Record<string, CacheItem> = {};
const dummyKeyMap: Record<string, ReadonlyArray<CacheItem>> = {};

type CacheItem = {
  key: string;
  val: any;
  expiresInMs?: number;
  timeout?: ICancellablePromise;
  dep: ReadonlyArray<string>;
};

/**
 * Puts cacheItem into primary cache
 * Overwrites cacheItem on collision
 * @param {{key: String, val: *, expiresInMs: Number,[timeout]:Number , dep:String[]}} cacheItem
 */
function putToPrimaryCache(cacheItem: CacheItem): void {
  removeTheOneItem(cacheItem.key);

  primaryCache[cacheItem.key] = cacheItem;
}

function removeTheOneItem(primaryKey: string): void {
  primaryCache[primaryKey]?.timeout?.cancel();
  delete primaryCache[primaryKey];
}

/**
 * Registers dependency in dependency table
 * @param {String} dummyKey
 * @param {{key: String, val: *, expiresInMs: Number,[timeout]:Number , dep:String[]}} cacheItem
 */
function registerDummyKey(dummyKey: string, cacheItem: CacheItem): void {
  if (typeof dummyKey !== 'string') {
    throw new Error('Cache key must be a string.');
  }
  const cacheItems = dummyKeyMap[dummyKey];
  if (Array.isArray(cacheItems)) {
    cacheItems.push(cacheItem);
  } else {
    dummyKeyMap[dummyKey] = [cacheItem];
  }
}

/**
 * Gets cacheItem from cache. If not available, gets it and puts it to cache. Then works the value.
 * This is the async variant of the method for async getters
 * The pending promise is cached to ensure only one execution at a time
 * but when the promise fails, it is removed from the cache so the next call has a chance to execute correctly
 * @param {String} key Key of the cacheItem to retrieve
 * @param {String[]} dummyKeys Keys that can invalidate val. Can be null.
 * @param {getValue} getValue Gets the cacheItem for the cache
 * @param {Number} [expiration] Number of milliseconds from now
 * @param {AbortSignal} [abortSignal] Request abort signal
 */
async function cache<ReturnType>(
  key: string,
  dummyKeys: ReadonlyArray<string> | null,
  getValue: () => Promise<ReturnType>,
  expiration?: number,
  abortSignal?: AbortSignal,
): Promise<ReturnType> {
  abortSignal?.throwIfAborted();

  const cacheItem = Cache.get(key);
  if (cacheItem !== undefined) {
    return cacheItem as Promise<ReturnType>;
  }

  try {
    const promise = getValue();
    Cache.put(key, dummyKeys, promise, expiration);

    const onAbort = () => Cache.throwAway(key);
    abortSignal?.addEventListener('abort', onAbort);

    const value = await promise;

    abortSignal?.removeEventListener('abort', onAbort);
    return value;
  } catch (error) {
    Cache.throwAway(key);
    throw error;
  }
}

/**
 * Composes key from keyParts
 * @param keyParts
 * @returns {string}
 */
function getKey(...keyParts: ReadonlyArray<string | undefined>): string {
  return keyParts.join('$');
}

/**
 * Retrieves item from cache
 * @param key Key of the item to retrieve
 * @returns {Object} Cached object or undefined
 */
function get(key: string): Promise<unknown> | undefined {
  return primaryCache[key]?.val;
}

function createCacheItem(
  key: string,
  dummyKeys: ReadonlyArray<string> | null,
  cachedValue: Promise<unknown>,
  expiresInMs?: number,
): CacheItem {
  const timeout = expiresInMs
    ? delay(expiresInMs)
        .then(() => Cache.throwAway(key))
        .catch(swallowCancelledPromiseError)
    : undefined;

  return {
    key,
    val: cachedValue,
    expiresInMs,
    timeout,
    dep: dummyKeys || [],
  };
}

/**
 * Puts given item to cache
 * @param key Key
 * @param {String[]} dummyKeys Keys that can invalidate cachedValue. Can be null.
 * @param cachedValue Item
 * @param {Number} [expirationInMs] Number of milliseconds from now
 */
function put(
  key: string,
  dummyKeys: ReadonlyArray<string> | null,
  cachedValue: Promise<unknown>,
  expirationInMs?: number,
): void {
  if (cachedValue === undefined) {
    return;
  }
  if (!isString(key)) {
    throw new Error('Cache key must be a string.');
  }
  const cacheItem = createCacheItem(key, dummyKeys, cachedValue, expirationInMs);
  putToPrimaryCache(cacheItem);
  if (Array.isArray(dummyKeys)) {
    dummyKeys.forEach((dummyKey) => {
      registerDummyKey(dummyKey, cacheItem);
    });
  }
}

/**
 * Removes all items with given key from cache
 * @param key The key to the cache item to remove
 */
function throwAway(key: string): void {
  removeTheOneItem(key);

  const dummies = dummyKeyMap[key];
  delete dummyKeyMap[key];
  if (Array.isArray(dummies)) {
    dummies.forEach((item) => {
      Cache.throwAway(item.key);
    });
  }
}

export const Cache = {
  cache,
  put,
  get,
  throwAway,
  getKey,
} as const;
