import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { InnovationLabFeatureCodename } from '../applications/environmentSettings/innovationLab/types/InnovationLabFeatureCodenames.ts';
import { IInnovationLabRepository } from './interfaces/IInnovationLabRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const innovationLabRepository: RepositoryWithContext<IInnovationLabRepository> = {
  getFeatures(requestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/early-access-program/features`;
    return restProvider.get(url, undefined, abortSignal, requestContext);
  },
  enableFeature(
    requestContext: IRequestContext,
    featureName: InnovationLabFeatureCodename,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/early-access-program/features/${featureName}`;
    return restProvider.put(url, undefined, abortSignal, requestContext);
  },
  disableFeature(
    requestContext: IRequestContext,
    featureName: InnovationLabFeatureCodename,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      requestContext.projectId,
    )}/early-access-program/features/${featureName}`;
    return restProvider.delete(url, abortSignal, requestContext);
  },
};
