import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IEventRepository } from './interfaces/IEventRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const eventRepository: RepositoryWithContext<IEventRepository> = {
  async trackAnonymousUserEventWithData(requestContext: IRequestContext, eventName, data) {
    const url = `${getUrlFactory().getDraftApiUrl()}/event`;
    try {
      return await restProvider.post(
        url,
        {
          eventName,
          userId: 'anonymous',
          data,
        },
        undefined,
        requestContext,
      );
    } catch {
      return null;
    }
  },
};
