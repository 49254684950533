import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IGeneralPropertyRepository } from './interfaces/IGeneralPropertyRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { GeneralPropertyServerModel } from './serverModels/GeneralPropertyServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const generalPropertyRepository: RepositoryWithContext<IGeneralPropertyRepository> = {
  getProperties(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<GeneralPropertyServerModel>> {
    const url = `${getUrlFactory().getDraftApiUrl()}/general-property`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
