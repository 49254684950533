import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { QueryStringParams } from '../_shared/utils/buildUrlQueryString.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { getContentTypeKeyByProjectId } from './cacheKeys/itemCacheUtils.ts';
import { IContentTypeRepository } from './interfaces/IContentTypeRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ContentTypeServerModel,
  ContentTypeUsageServerModel,
  ContentTypesServerModel,
  CreateNewContentTypeServerModel,
  TypeListOperationResultServerModel,
} from './serverModels/contentModels/contentTypeServerModels.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const typeKeyBase = 'contentType';
const oneSecondInMs = 1000;

export const invalidateContentTypeCache = (projectId: Uuid | undefined) => {
  const anyContentTypeCacheKey = getContentTypeKeyByProjectId(projectId);
  Cache.throwAway(anyContentTypeCacheKey);
};

export const contentTypeRepository: RepositoryWithContext<IContentTypeRepository> = {
  getContentTypes(
    requestContext: IRequestContext,
    queryParams: QueryStringParams | null,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypesServerModel> {
    const cacheKey = Cache.getKey(
      typeKeyBase,
      requestContext.projectId,
      JSON.stringify(queryParams),
    );
    const dummyKeys = [getContentTypeKeyByProjectId(requestContext.projectId)];
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type`;
    const getValue = () => restProvider.get(url, queryParams, abortSignal, requestContext);

    return Cache.cache(cacheKey, dummyKeys, getValue, oneSecondInMs, abortSignal);
  },

  getContentType(
    requestContext: IRequestContext,
    contentTypeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type/${contentTypeId}`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  createContentType(
    requestContext: IRequestContext,
    contentType: CreateNewContentTypeServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type`;
    return restProvider.post(url, contentType, abortSignal, requestContext);
  },

  updateContentType(
    requestContext: IRequestContext,
    type: ContentTypeServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type/${
      type._id
    }`;
    return restProvider.put(url, type, abortSignal, requestContext);
  },

  getUsageCountByContentType(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeUsageServerModel[]> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type/content-types-usage`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  archiveContentType(
    requestContext: IRequestContext,
    typeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const archiveContentTypeBody = '{"archived": true}';
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type/${typeId}/archive`;

    return restProvider.put(url, archiveContentTypeBody, abortSignal, requestContext);
  },

  restoreContentType(
    requestContext: IRequestContext,
    typeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const restoreContentTypeBody = '{"archived": false}';
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type/${typeId}/archive`;
    return restProvider.put(url, restoreContentTypeBody, abortSignal, requestContext);
  },

  archiveContentTypes(
    requestContext: IRequestContext,
    typeIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<TypeListOperationResultServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const archiveContentTypesBody = {
      typeIds,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type/archive`;
    return restProvider.put(url, archiveContentTypesBody, abortSignal, requestContext);
  },

  restoreContentTypes(
    requestContext: IRequestContext,
    typeIds: UuidArray,
    abortSignal?: AbortSignal,
  ): Promise<TypeListOperationResultServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const restoreContentTypesBody = {
      typeIds,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/type/restore`;
    return restProvider.put(url, restoreContentTypesBody, abortSignal, requestContext);
  },

  duplicateContentType(
    requestContext: IRequestContext,
    typeId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ContentTypeServerModel> {
    invalidateContentTypeCache(requestContext.projectId);

    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/type/${typeId}/duplicate`;
    return restProvider.post(url, null, abortSignal, requestContext);
  },
};
