import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ISpacesRepository } from './interfaces/ISpacesRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const getDraftProjectSpacesApiUrl = (projectId?: Uuid): string =>
  `${getUrlFactory().getDraftProjectApiUrl(projectId)}/spaces`;

const restProvider = createRestProvider(createAjaxWithCredentials());

const getSpacesCacheKey = (projectId: Uuid | undefined) => Cache.getKey('spaces', projectId);

const getGeneralInvalidationKey = (projectId: Uuid | undefined) => getSpacesCacheKey(projectId);

const throwAwaySpaceDependentCache = (projectId: Uuid | undefined) => {
  Cache.throwAway(getSpacesCacheKey(projectId));
};

const cacheStaleTime = getMilliseconds({ minutes: 2 });

export const spacesRepository: RepositoryWithContext<ISpacesRepository> = {
  async getAll(requestContext, abortSignal?: AbortSignal) {
    const cacheKey = getSpacesCacheKey(requestContext.projectId);
    const invalidationKeys = [getGeneralInvalidationKey(requestContext.projectId)];

    const url = getDraftProjectSpacesApiUrl(requestContext.projectId);
    const getValue = () => restProvider.get(url, null, abortSignal, requestContext);

    return Cache.cache(cacheKey, invalidationKeys, getValue, cacheStaleTime, abortSignal);
  },

  async create(requestContext, space, abortSignal?: AbortSignal) {
    throwAwaySpaceDependentCache(requestContext.projectId);

    const url = getDraftProjectSpacesApiUrl(requestContext.projectId);

    return await restProvider.post(url, space, abortSignal, requestContext);
  },

  async update(requestContext, space, abortSignal?: AbortSignal) {
    throwAwaySpaceDependentCache(requestContext.projectId);

    const url = `${getDraftProjectSpacesApiUrl(requestContext.projectId)}/${space.id}`;

    return await restProvider.put(url, space, abortSignal, requestContext);
  },

  async delete(requestContext, spaceId, abortSignal?: AbortSignal) {
    throwAwaySpaceDependentCache(requestContext.projectId);

    const url = `${getDraftProjectSpacesApiUrl(requestContext.projectId)}/${spaceId}`;

    return await restProvider.delete(url, abortSignal, requestContext);
  },

  async restore(requestContext, spaceId, abortSignal?: AbortSignal) {
    throwAwaySpaceDependentCache(requestContext.projectId);

    const url = `${getDraftProjectSpacesApiUrl(requestContext.projectId)}/${spaceId}/restore`;

    return await restProvider.put(url, null, abortSignal, requestContext);
  },
};
