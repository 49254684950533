function hasFunction<T>(obj: T, functionName: keyof T): boolean {
  return obj[functionName] && typeof obj[functionName] === 'function';
}

export function toDTO(obj: any) {
  if (obj === null || obj === undefined) {
    return null;
  }
  if (typeof obj === 'object') {
    if (hasFunction(obj, 'toDTO')) {
      return obj.toDTO();
    }
    // Is object immutable?
    if (hasFunction(obj, 'toJS')) {
      return filterOurPropertiesFromImmutableObject(obj).toJS();
    }
    return obj;
  }
}

function filterOurPropertiesFromImmutableObject(obj: any) {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (hasFunction(obj, 'toDTO')) {
    return obj.toDTO();
  }

  if (!hasFunction(obj, 'toJS') || !hasFunction(obj, 'map')) {
    return obj;
  }

  return obj
    .map((value: any, key: string) => {
      // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
      if (key[0] === '_' && key !== '_id') {
        return undefined;
      }

      return filterOurPropertiesFromImmutableObject(value);
    })
    .filterNot((value: any) => value === undefined);
}
