import { Cache } from '../../_shared/utils/cache.ts';

export function getEntryKeyByProjectId(projectId: Uuid | undefined): string {
  return Cache.getKey('entry', 'any', projectId);
}

export function getItemKeyByProjectId(projectId: Uuid | undefined): string {
  return Cache.getKey('item', 'any', projectId);
}

export function getContentItemKeyByProjectId(projectId: Uuid | undefined): string {
  return Cache.getKey('contentItem', 'any', projectId);
}

export function getContentTypeKeyByContentTypeId(contentTypeId: Uuid): string {
  return Cache.getKey('contentType', 'byId', contentTypeId);
}

export function getContentTypeKeyByProjectId(projectId: Uuid | undefined): string {
  return Cache.getKey('contentType', projectId, 'any');
}

export function getContentTypeSnippetKeyByProjectId(projectId: Uuid | undefined): string {
  return Cache.getKey('contentTypeSnippet', projectId, 'any');
}
