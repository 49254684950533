import { getUrlFactory } from '../utils/urlFactory.ts';

interface IWebUrls {
  readonly blog: string;
  readonly landingPage: string;
  readonly pricing: string;
  readonly pricingFaq: string;
  readonly productUpdateEndpoint: string;
  readonly termsOfService: string;
  readonly newBrandAnnouncement: string;
}

export const getLandingPageLinks = (): IWebUrls => {
  const landingPageUrl = getUrlFactory().getLandingPageUrl();
  return {
    blog: `${landingPageUrl}/blog`,
    landingPage: landingPageUrl,
    pricing: `${landingPageUrl}/pricing`,
    pricingFaq: `${landingPageUrl}/pricing#pricing-faq`,
    productUpdateEndpoint: getUrlFactory().getProductUpdateEndpoint(),
    termsOfService: `${landingPageUrl}/terms-of-service`,
    newBrandAnnouncement: `${landingPageUrl}/blog/kontent-ai-has-raised-40-million-in-growth-capital`,
  };
};
