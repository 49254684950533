import { Cache } from '../../_shared/utils/cache.ts';

export function getAssetKeyById(assetId: Uuid, projectId: Uuid): string {
  return Cache.getKey('asset', projectId, assetId);
}

export function getAssetLinksKey(assetId: Uuid, projectId: Uuid): string {
  return Cache.getKey('assetLinks', assetId, projectId);
}

export function getAssetUsedInKey(assetId: Uuid, projectId: Uuid): string {
  return Cache.getKey('assetUsedIn', assetId, projectId);
}

export function touchAssetDependencies(assetId: Uuid, projectId: Uuid): void {
  Cache.throwAway(getAssetKeyById(assetId, projectId));
}

export function touchAssetsDependencies(assetIds: UuidArray, projectId: Uuid): void {
  assetIds.forEach((assetId) => Cache.throwAway(getAssetKeyById(assetId, projectId)));
}
