import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { ITranslateVariantRepository } from './interfaces/ITranslateVariantRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { CreateTranslationTaskServerModel } from './serverModels/CreateTranslationTaskServerModel.type.ts';
import { IVariantTranslationTaskServerModel } from './serverModels/VariantTranslationTaskServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const translateVariantRepository: RepositoryWithContext<ITranslateVariantRepository> = {
  startTranslation(
    requestContext: IRequestContext,
    projectEnvironmentId: Uuid,
    itemId: Uuid,
    variantId: Uuid,
    params: CreateTranslationTaskServerModel,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectEnvironmentId,
    )}/item/${itemId}/variant/${variantId}/translation`;

    return restProvider.post(url, params, abortSignal, requestContext);
  },

  cancelTranslation(
    requestContext: IRequestContext,
    projectEnvironmentId: Uuid,
    itemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectEnvironmentId,
    )}/item/${itemId}/variant/${variantId}/translation`;

    return restProvider.delete(url, abortSignal, requestContext);
  },

  getTranslationStatus(
    requestContext: IRequestContext,
    projectEnvironmentId: Uuid,
    itemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IVariantTranslationTaskServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectEnvironmentId,
    )}/item/${itemId}/variant/${variantId}/translation`;

    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
