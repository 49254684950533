import { getLandingPageLinks } from '../_shared/constants/landingPageLinks.ts';
import { createAjaxWithoutCredentials } from '../_shared/utils/ajax.ts';
import { createRestProvider } from '../_shared/utils/restProvider.ts';
import { IProductUpdateRepository } from './interfaces/IProductUpdateRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ILatestProductUpdateInfoServerModel } from './serverModels/ILatestProductUpdateInfoServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithoutCredentials());

export const productUpdateRepository: RepositoryWithContext<IProductUpdateRepository> = {
  getLatest(): Promise<ILatestProductUpdateInfoServerModel> {
    return restProvider.get(
      getLandingPageLinks().productUpdateEndpoint,
      null,
      undefined,
      undefined,
      { omitHeaders: ['ClientVersion'] },
    );
  },
};
